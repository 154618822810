/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 *  Edit page
 *
 * @author Naseef O
 *
 */

import clsx from "clsx";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Label from "../../../common/core/Label";
import Input from "../../../common/core/Input";
import Form from "../../../common/core/Form";
import FormFeedback from "../../../common/core/FormFeedback";
import FormGroup from "../../../common/core/FormGroup";
import Button from "../../../common/core/Button";
import styles from "../css/EditPage.module.css";
import LocaleDropdownContainer from "../../LocaleDropdown/containers/LocaleDropdownContainer";
import publishIcon from "../../../common/assets/images/svg/insert_photo.svg";
import preview from "../../../common/assets/images/svg/preview.svg";
import copyIcon from "../../../common/assets/images/svg/copy.svg";
import imageUtils from "../../../common/utils/imageUtils";
import ImageUploader from "./ImageUploader";
import { uploadImage } from "../../../api/pageContentServices";
import ImageList from "./ImageList";
import InputWrapper from "../../../common/components/InputWrapper";
import DateRangePicker from "../../../common/components/DateRangePicker";

const EditPage = ({
  pageForm,
  handleChange,
  handleSave,
  handleChangeEditor,
  content,
  handleSubmit,
  register,
  errors,
  handleRedirectToStaticPages,
  localeHandler,
  isEdit,
  setValue,
}) => {
  const { t } = useTranslation(["common"]);
  const [enablePreview, setPreviewEnabled] = useState(false);
  const [modal, setModalOpen] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [popOver, setPopOverOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modal);
  };

  const togglePopOver = () => {
    setPopOverOpen(!popOver);
  };
  const [defaultMediaUrl, setDefaultMediaUrl] = useState("");
  const [imageUrls, setImageUrls] = useState([]);

  /**
 * This method is used to handle change file
 *
 *
 * @param {*} event
 */
  const handleChangeFile = async (event) => {
    const file = event.target.files[0];
    if (file && file.size > 9242880) {
      alert("Image must be less than 9 megabytes!");
    } else if (file) {
      setIsLoading(true);
      const url = await imageUtils.readURL(file);
      const imageFile = imageUtils.dataURLtoFile(url, "Moz-com");
      setModalOpen(true);
      await uploadImage(imageFile).then((response) => {
        if (response && response.success && response.data) {
          setDefaultMediaUrl(response.data.largeMediaUrl);
          const newImageUrls = [...imageUrls];
          newImageUrls.push(response.data.largeMediaUrl);
          setImageUrls(newImageUrls);
        }
        setIsLoading(false);
      });
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(handleSave)}>
        <Row className="pageHeader common-page-card">
          <Col xs="12" md="7" className="text-truncate">
            <span className="pageText">{isEdit ? "Edit" : "Add"}</span>
          </Col>
          <Col xs="12" md="5" className="d-flex justify-content-end">
            <Button
              type="button"
              onClick={handleRedirectToStaticPages}
              className="buttonCancel"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="secondary"
              className="ml-2 buttonSave bg-color-tertiary-main"
            >
              Save
            </Button>
          </Col>
        </Row>
        {isEdit
          && (
            <Row className="pageHeader common-page-card">
              <Col xs="12" md="7">
                <LocaleDropdownContainer
                  isIndocument={false}
                  entityName="pageContent"
                  localeHandler={localeHandler}
                />
              </Col>
            </Row>
          )}
        <Row className={clsx(styles.block2, "m-0")}>
          <Col xs="12">
            <Row>
              <Col xs="12">
                <FormGroup className="d-flex ">
                  <Label>Status</Label>
                  <div
                    className={clsx("custom-control", "custom-switch", "ml-1")}
                  >
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="active"
                      name="active"
                      checked={pageForm.active}
                      onChange={(e) => handleChange(e)}
                    />
                    <Label className="custom-control-label" htmlFor="active" />
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="6">
                <FormGroup>
                  <Label className="formTextLabel" for="exampleEmail">
                    Page name
                  </Label>
                  <InputWrapper
                    className="formText"
                    placeholder="Name"
                    name="pageHeader"
                    defaultValue={pageForm.pageHeader}
                    onChange={handleChange}
                    innerRef={register({
                      required: t("common:validation_name"),
                    })}
                    invalid={!!(errors.pageHeader && errors.pageHeader.message)}
                    setValue={setValue}
                  />
                  <FormFeedback>
                    {errors.pageHeader && errors.pageHeader.message}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col xs="6">
                <FormGroup>
                  <Label className="formTextLabel" for="exampleEmail">
                    URL
                  </Label>
                  <InputWrapper
                    className="formText"
                    placeholder="URL"
                    name="url"
                    defaultValue={pageForm.url}
                    onChange={handleChange}
                    innerRef={register({
                      required: t("common:validation_url"),
                    })}
                    invalid={!!(errors.url && errors.url.message)}
                    setValue={setValue}
                  />
                  <FormFeedback>
                    {errors.url && errors.url.message}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6" className="d-flex align-items-center">
                <FormGroup className="w-100">
                  <DateRangePicker
                    labelClass="formTextLabel"
                    timeFormat="HH:mm:ss"
                    dateFormat="YYYY-MM-DD"
                    startDateProps={{
                      label: "Start date",
                      noStartDate: false,
                      value: pageForm.startDate,
                      name: "startDate",
                    }}
                    endDateProps={{
                      label: "End date",
                      noEndDate: false,
                      value: pageForm.endDate,
                      name: "endDate",
                    }}
                    onChange={(e) => handleChange(e)}
                    id="pcvv"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="common-page-card">
              <Col className="p-0">
                <Row
                  className="justify-content-between m-0"
                  style={{
                    borderBottom: "1px solid #ccc",
                    paddingBottom: "4px",
                  }}
                >
                  <Col className="p-0">
                    <div>
                      HTML Editor
                    </div>
                  </Col>
                  <Col className="p-0">
                    <div className=" d-flex justify-content-end align-items-center" onC>
                      <div
                        role="presentation"
                        className="pr-4 cursor-pointer"
                        onClick={() => {
                          setPreviewEnabled(!enablePreview);
                        }}
                      >
                        <span className="mr-2">
                          {!enablePreview ? "Preview" : "Cancel"}
                        </span>
                        <img src={preview} alt="preview" />
                      </div>
                      <div
                        className="cursor-pointer ml-4"
                        role="presentation"
                      >
                        <label htmlFor="static-page-img-uploader" className="btn btn-default w-100 h-100 p-0 m-0">
                          <input
                            type="file"
                            id="static-page-img-uploader"
                            hidden
                            onChange={(e) => handleChangeFile(e)}
                            accept="image/jpeg, image/jpg, image/svg+xml, image/png"
                          />
                          <img src={publishIcon} alt="upload" title="Choose image" />
                        </label>
                        <ImageUploader
                          defaultMediaUrl={defaultMediaUrl}
                          loading={loading}
                          toggle={toggleModal}
                          isOpen={modal}
                        />
                      </div>
                      {Array.isArray(imageUrls) && imageUrls.length > 0 && (
                        <div
                          className=" ml-4"
                          role="presentation"
                        >
                          <span
                            id="popover-uploaded-images"
                            className="cursor-pointer"
                            role="presentation"
                            onClick={() => togglePopOver()}
                          >
                            <img src={copyIcon} alt="copy" />
                          </span>
                          <ImageList
                            imageUrls={imageUrls}
                            toggle={togglePopOver}
                            isOpen={popOver}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row className=" common-page-card">
                  <Col>
                    {!enablePreview ? (
                      <div className=" row">
                        <Input
                          style={{
                            height: "600px",
                            backgroundColor: " black",
                            color: "white",
                          }}
                          type="textarea"
                          value={content}
                          onChange={(e) => handleChangeEditor(e)}
                        />
                      </div>
                    )
                      : (
                        <div>
                          <div
                            dangerouslySetInnerHTML={
                              { __html: content || "" }
                            }
                            style={{
                              border: "1px solid #cccc",
                              padding: "10px",
                            }}
                          />
                        </div>
                      )}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs="6">
                <FormGroup>
                  <Label className="formTextLabel" for="exampleEmail">
                    Page title
                  </Label>
                  <InputWrapper
                    className="formText"
                    placeholder="Page title"
                    name="pageTitle"
                    defaultValue={pageForm.pageTitle}
                    onChange={handleChange}
                    innerRef={register({
                      required: t("common:validation_page_title"),
                    })}
                    invalid={
                      !!(errors.pageTitle && errors.pageTitle.message)
                    }
                    setValue={setValue}
                  />
                  <FormFeedback>
                    {errors.pageTitle && errors.pageTitle.message}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col xs="6">
                <FormGroup>
                  <Label className="formTextLabel" for="exampleEmail">
                    SEO tags
                  </Label>
                  <InputWrapper
                    className="formText"
                    placeholder="SEO tags"
                    name="metaKeywords"
                    defaultValue={pageForm.metaKeywords}
                    onChange={handleChange}
                    innerRef={register({
                      required: t("common:validation_seo-tags"),
                    })}
                    invalid={
                      !!(errors.metaKeywords && errors.metaKeywords.message)
                    }
                    setValue={setValue}
                  />
                  <FormFeedback>
                    {errors.metaKeywords && errors.metaKeywords.message}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs="6">
                <FormGroup>
                  <Label className="formTextLabel" for="exampleEmail">
                    SEO description
                  </Label>
                  <InputWrapper
                    value={pageForm.metaDescription}
                    className="formText"
                    placeholder="Description"
                    name="metaDescription"
                    defaultValue={pageForm.metaDescription}
                    onChange={handleChange}
                    innerRef={register({
                      required: t("common:validation_meta-desc"),
                    })}
                    invalid={
                      !!(
                        errors.metaDescription && errors.metaDescription.message
                      )
                    }
                    setValue={setValue}
                  />
                  <FormFeedback>
                    {errors.metaDescription && errors.metaDescription.message}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
};

EditPage.propTypes = {
  pageForm: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleChangeEditor: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  handleRedirectToStaticPages: PropTypes.func.isRequired,
  localeHandler: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default EditPage;
