/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

/** ===== TAG COMPONENTS =========== */
import { useTranslation } from "react-i18next";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Input from "../../../common/core/Input";
import Label from "../../../common/core/Label";
import InputGroup from "../../../common/core/InputGroup";
import InputGroupAddon from "../../../common/core/InputGroupAddon";
import ListGroup from "../../../common/core/ListGroup";
import ListGroupItem from "../../../common/core/ListGroupItem";


/** ===== IMAGE ICONS =========== */
import SearchIcon from "../../../common/assets/images/svg/search.svg";
import ClearIcon from "../../../common/assets/images/svg/clear.svg";

/** ========= CUSTOM COMPONENTS ========= */
import Image from "../../productManagement/components/Image";
import ProductRankManagementContainer from "../containers/ProductRankManagementContainer";

/** ======== MODULE STYLES ========== */
import styles from "../css/ProductSelection.module.css";

const ProductSelection = (props) => {
  const {
    productList, products, selectedProducts, setProductSelected,
    handleSearchFiled, searchKeyword, categoryId, showRanking, locale,
  } = props;

  const getPreviewImage = (medias) => {
    let imageUrl = null;
    if (Array.isArray(medias) && medias[0]) {
      imageUrl = medias[0].mediaThumbnailUrl;
    }
    return imageUrl;
  };

  const { t } = useTranslation(["common", "product"]);

  return (
    <>
      <Row className={clsx(styles.block4, "pt-1")}>
        <Col xs="12" sm="6" className={clsx(styles.rightDivider, "position-relative")}>
          {/*  ====== LIST AND SEARCH, AVAILABLE PRODUCTS S[0005]============ */}
          <span className="pageTitleText, fontsizeMediumBold ">
            {t("product:span_products")}
          </span>
          <span style={{ float: "right" }}>
            {t("product:span_total_linked_products")}
            {" "}
            {Array.isArray(selectedProducts) && selectedProducts.length}
          </span>
          <div className="pt-2">
            <InputGroup className={styles.search}>
              <Input placeholder="Search product ID, name" value={searchKeyword} onChange={handleSearchFiled} />
              <InputGroupAddon
                addonType="append"
                className="commonPointer"
              >
                <img
                  src={SearchIcon}
                  alt="search product"
                  className={styles.icon}
                />
              </InputGroupAddon>
            </InputGroup>
            <ListGroup className={clsx("py-1", styles.searchListContainer, "custom-scrollbar")} flush>
              {
                products.map((product) => (
                  <ListGroupItem key={product.id} className="px-2 py-1 border-0 d-flex ">
                    <div className="custom-control custom-checkbox text-truncate">
                      {/*
                    * maintaining separate state for selected products,
                    * checked logic by checking includes
                    * */}
                      <input
                        type="checkbox"
                        id={`productRadio${product.id}`}
                        checked={selectedProducts.includes(product.id)}
                        name={product.id}
                        className="custom-control-input"
                        readOnly
                      />
                      <Label
                        onClick={(o) => setProductSelected(product.id, o)}
                        className="custom-control-label text-black commonPointer mw-100 "
                        htmlFor={`productRadio${product.id}`}
                        title={product.name}
                      >
                        <span className="text-truncate d-block mw-100">{product.name}</span>
                      </Label>
                    </div>
                  </ListGroupItem>
                ))
              }
            </ListGroup>
          </div>
          {/*  ====== LIST AND SEARCH, AVAILABLE PRODUCTS E[0005]============ */}
        </Col>
        <Col xs="12" sm="6" className="px-4">
          {/*  ====== LISTING SELECTED PRODUCTS S[0006]============ */}
          <span className="pageTitleText, fontsizeMediumBold ">
            {t("product:span_product_list")}
          </span>
          <div className="pt-2">
            <Row className={clsx("pb-0", styles.searchListContainer, styles.selectedListContainer, "custom-scrollbar")}>
              {
                // filtering products array by selected products array
                productList.filter((product) => selectedProducts.includes(product.id))
                  .map((product) => (
                    <Col key={product.id} xs="6" sm="6" md="4" className={clsx(styles.selectedProduct, "px-2", "pb-3", "position-relative")} title={product.name}>
                      <Image src={getPreviewImage(product.medias)} alt={product.name} className="img-fluid" />
                      <span className="text-truncate d-block">
                        {product.name}
                      </span>
                      {/*  ====== REMOVE SELECTED ITEM S[0008]============ */}
                      {/* remove icon hidden by default, shows on mouse hover */}
                      <div className={styles.unselectProduct}>
                        <span onClick={(o) => setProductSelected(product.id, o)} className="commonPointer" role="button" tabIndex={0} onKeyPress={() => { }} title="Remove product">
                          <img src={ClearIcon} alt="Remove product" className={styles.icon} />
                        </span>
                      </div>
                      {/*  ====== REMOVE SELECTED ITEM E[0008]============ */}
                    </Col>
                  ))
              }
            </Row>
          </div>
          {/*  ====== LISTING SELECTED PRODUCTS E[0006]============ */}
        </Col>
      </Row>
      {/*  ====== SEQUENCING COMPONENT ============ */}
      {showRanking
        && (
          <Row className={clsx(styles.block4, "pt-1")}>
            <Col xs="24" sm="12" className="px-4">
              {/*  ====== PAGE COMPONENT HEADER E[0001]============ */}
              <div className="common-page-card">
                <div className="py-2">
                  {/*  ====== ADD PRODUCTS RANKS S[0003]============ */}
                  <ProductRankManagementContainer
                    categoryId={categoryId}
                    locale={locale}
                  />
                  {/*  ====== ADD PRODUCTS E[0003]============ */}
                </div>
              </div>
            </Col>
          </Row>
        )}
    </>
  );
};

ProductSelection.propTypes = {
  // products
  productList: PropTypes.arrayOf(PropTypes.any).isRequired,
  products: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedProducts: PropTypes.arrayOf(PropTypes.any).isRequired,
  setProductSelected: PropTypes.func.isRequired,
  // search product
  searchKeyword: PropTypes.string.isRequired,
  handleSearchFiled: PropTypes.func.isRequired,
  categoryId: PropTypes.string.isRequired,
  showRanking: PropTypes.bool.isRequired,
};


export default ProductSelection;
