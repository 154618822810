/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Aardra S
 */

import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

/** ========= CUSTOM COMPONENTS ========= */
import LocationSelector from "../../../common/components/LocationSelector";

/** ===== TAG COMPONENTS =========== */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Label from "../../../common/core/Label";
import Input from "../../../common/core/Input";
import FormGroup from "../../../common/core/FormGroup";
import InputGroup from "../../../common/core/InputGroup";
import InputGroupAddon from "../../../common/core/InputGroupAddon";
import InputGroupText from "../../../common/core/InputGroupText";

/** ======== MODULE STYLES ========== */
import styles from "../css/StoreForm.module.css";

const EditContactInfoForm = (props) => {
  const {
    handleFormObjectChange,
    contactInfo,
    onLocationChange,
    cityList,
    translatableFields,
    selectedLanguage,
    areas,
  } = props;
  /** internationalization using i18n from common and product  */
  const { t } = useTranslation(["common", "store"]);

  return (
    <>
      <Row className={styles.block3}>
        <Col xs="12">
          <Row>
            {contactInfo && (
              <div className="container">
                <div className="row">
                  <div className="col-6">
                    <div className=" row store-location font-weight-bold">
                      <div className="col-10">
                        <FormGroup>
                          <Label className="formTextLabel">
                            {t("store:label_address_line_1")}
                            <span className="pl-1 text-danger">*</span>
                          </Label>
                          <Input
                            className="formText"
                            placeholder={t("store:placeholder_address_line_1")}
                            name="contactInfo"
                            value={contactInfo.addressLine1}
                            onChange={(o) =>
                              handleFormObjectChange(
                                contactInfo,
                                "addressLine1",
                                o
                              )
                            }
                            disabled={
                              Array.isArray(translatableFields)
                                ? selectedLanguage !== "en_QA" &&
                                  !translatableFields.includes(
                                    "contactInfo.addressLine1"
                                  )
                                : false
                            }
                            required
                          />
                        </FormGroup>
                      </div>
                    </div>
                    <div className=" row store-location font-weight-bold">
                      <div className="col-10">
                        <FormGroup>
                          <Label className="formTextLabel">
                            {t("store:label_address_line_2")}
                            <span className="pl-1 text-danger">*</span>
                          </Label>
                          <Input
                            className="formText"
                            placeholder={t("store:placeholder_address_line_2")}
                            name="contactInfo"
                            value={contactInfo.addressLine2}
                            onChange={(o) =>
                              handleFormObjectChange(
                                contactInfo,
                                "addressLine2",
                                o
                              )
                            }
                            disabled={
                              Array.isArray(translatableFields)
                                ? selectedLanguage !== "en_QA" &&
                                  !translatableFields.includes(
                                    "contactInfo.addressLine2"
                                  )
                                : false
                            }
                            required
                          />
                        </FormGroup>
                      </div>
                    </div>
                    <div className=" row store-location font-weight-bold">
                      <div className="col-10">
                        <FormGroup>
                          <Label className="formTextLabel">
                            {t("store:label_city")}
                            <span className="pl-1 text-danger">*</span>
                          </Label>
                          <Input
                            className="formText"
                            placeholder={t("store:placeholder_city")}
                            type="select"
                            name="contactInfo"
                            value={contactInfo.city}
                            onChange={(o) =>
                              handleFormObjectChange(contactInfo, "city", o)
                            }
                          >
                            {cityList.map((type) => (
                              <option value={type.value}>{type.label}</option>
                            ))}
                          </Input>
                        </FormGroup>
                      </div>
                    </div>
                    <div className=" row store-location font-weight-bold">
                      <div className="col-10">
                        <FormGroup>
                          <Label className="formTextLabel">
                            {t("store:label_area")}
                            <span className="pl-1 text-danger">*</span>
                          </Label>
                          <Input
                            className="formText"
                            type="select"
                            name="contactInfo"
                            value={contactInfo.area}
                            onChange={(o) =>
                              handleFormObjectChange(contactInfo, "area", o)
                            }
                            required
                          >
                            <option value="" hidden>
                              {t("store:placeholder_area")}
                            </option>
                            {[
                              ...areas.map((area) => (
                                <option value={area.code}>
                                  {area.displayName}
                                </option>
                              )),
                            ]}
                          </Input>
                        </FormGroup>
                      </div>
                    </div>
                  </div>

                  <div className="col-6 mb-3">
                    <div style={{ height: "100%", width: "100%" }}>
                      <LocationSelector onChange={onLocationChange} />
                    </div>
                  </div>

                  <div className="col-6">
                    <div className=" row store-location font-weight-bold">
                      <div className="col-10">
                        <FormGroup>
                          <Label className="formTextLabel">
                            {t("store:label_country")}
                            <span className="pl-1 text-danger">*</span>
                          </Label>
                          <Input
                            className="formText"
                            placeholder={t("store:placeholder_country")}
                            name="contactInfo"
                            value={contactInfo.country}
                            onChange={(o) =>
                              handleFormObjectChange(contactInfo, "country", o)
                            }
                            required
                            disabled={
                              Array.isArray(translatableFields)
                                ? selectedLanguage !== "en_QA" &&
                                  !translatableFields.includes(
                                    "contactInfo.country"
                                  )
                                : false
                            }
                          />
                        </FormGroup>
                      </div>
                    </div>
                    <div className=" row store-location font-weight-bold">
                      <div className="col-10">
                        <FormGroup>
                          <Label className="formTextLabel">
                            {t("store:label_phone_No")}
                            <span className="pl-1 text-danger">*</span>
                          </Label>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <span className="pl-1">
                                  {contactInfo.dialCode}
                                </span>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              className="formText"
                              placeholder={t("store:placeholder_phone_No")}
                              name="contactInfo"
                              value={contactInfo.telephone1}
                              onChange={(o) =>
                                handleFormObjectChange(
                                  contactInfo,
                                  "telephone1",
                                  o
                                )
                              }
                            />
                          </InputGroup>
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    {/* <div className=" row store-location font-weight-bold">
                      <div className="col-10">
                        <FormGroup>
                          <Label className="formTextLabel">
                            {t("store:label_state")}
                          </Label>
                          <Input
                            className="formText"
                            placeholder={t("store:placeholder_state")}
                            name="contactInfo"
                            value={contactInfo.state}
                            onChange={(o) => handleFormObjectChange(contactInfo, "state", o)}
                            disabled={Array.isArray(translatableFields) ? selectedLanguage !== "en_QA" && !translatableFields.includes("contactInfo.state") : false}
                          />
                        </FormGroup>
                      </div>
                    </div> */}
                    <div className=" row store-location font-weight-bold">
                      <div className="col-10">
                        <FormGroup>
                          <Label className="formTextLabel">
                            {t("store:label_e_mail")}
                            <span className="pl-1 text-danger">*</span>
                          </Label>
                          <Input
                            className="formText"
                            placeholder={t("store:placeholder_e_mail")}
                            name="contactInfo"
                            value={contactInfo.email}
                            onChange={(o) =>
                              handleFormObjectChange(contactInfo, "email", o)
                            }
                            disabled={
                              Array.isArray(translatableFields)
                                ? selectedLanguage !== "en_QA" &&
                                  !translatableFields.includes("storeId")
                                : false
                            }
                            required
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
};

EditContactInfoForm.propTypes = {
  handleFormObjectChange: PropTypes.func.isRequired,
  contactInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  onLocationChange: PropTypes.func.isRequired,
  cityList: PropTypes.objectOf(PropTypes.any).isRequired,
  translatableFields: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedLanguage: PropTypes.string.isRequired,
  areas: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default EditContactInfoForm;
