/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Angela
 */

import React, { useState, useEffect } from "react";
import CacheManagement from "../component/CacheManagement";
import { deleteAllCache } from "../../../api/cacheService";
import ConfirmationModal from "../../../common/components/ConfirmationModal";

const CacheManagementContainer = () => {
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState({
    type: null,
    message: "",
  });

  const toggleModal = () => {
    setModal(!modal);
  }

  /**
   * method for delete confirm
   */
  const confirmDelete = async () => {
    const response = await deleteAllCache();
    if (response && response.success) {
      setMessage({ type: "success", message: response.messages[0] || "" });
      setTimeout(() => {
        setMessage({ type: null, message: "" });
      }, 3000);
    }
    else {
      setMessage({ type: "warning", message: response.messages[0] || "Something went wrong" });
      setTimeout(() => {
        setMessage({ type: null, message: "" });
      }, 3000);
    }
    toggleModal();
  };

  return (
    <>
      <CacheManagement
        toggleModal={toggleModal}
        message={message}
      />
      <ConfirmationModal
        isOpen={modal}
        toggleOpen={toggleModal}
        togglClose={toggleModal}
        handleConfirm={confirmDelete}
        content=" Are you sure you want to clear cache ?"
      />
    </>
  );
};

export default CacheManagementContainer;
