/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

import clsx from "clsx";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ===== TAG COMPONENTS =========== */
import Col from "../../../common/core/Col";
import Row from "../../../common/core/Row";
import Form from "../../../common/core/Form";
import Button from "../../../common/core/Button";
import Table from "../../../common/core/Table";
import Nav from "../../../common/core/Nav";
import NavItem from "../../../common/core/NavItem";
import NavLink from "../../../common/core/NavLink";
import TabContent from "../../../common/core/TabContent";
import TabPane from "../../../common/core/TabPane";

/** ========== SUB COMPONENT ================ */
import ProductSelectionContainer from "../../categoryManagement/containers/ProductSelectionContainer";
import StaticDetailsView from "./StaticDetailsView";

/** ========== SUB COMPONENT ================ */
import ProductForm from "./EditForm";
import Image from "./Image";

/** ===== IMAGE ICONS =========== */
import DeleteIcon from "../../../common/assets/images/svg/delete.svg";
import EditIcon from "../../../common/assets/images/svg/edit.svg";

/** ======== MODULE STYLES ========== */
import styles from "../css/EditProduct.module.css";
import validateForm from "../../../common/utils/validateForm";

const EditProduct = (props) => {
  const {
    selectedCategories,
    categories,
    productType,
    name,
    enableProduct,
    skuName,
    listPrice,
    quantity,
    onSale,
    images,
    attributes,
    description,
    longDescription,
    startDate, endDate,
    landingPageUrl,
    handleChange,
    handleFormChange,
    onCancel,
    onSubmit,
    onSubmitting,
    skus,
    handleEditSku,
    handleRedirectToModifySku,
    relatedProductDetails,
    retailerExtensions,
    productDetails,
    primaryParentCategory,
    id,
    productOptions,
  } = props;
  /** internationalization using i18n from common and product  */
  const { t } = useTranslation(["common", "product"]);

  const [activeTab, setActiveTab] = useState("1");

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  /**
   * This method is used to return image url form array
   * @param {Array} medias
   */
  const getPreviewImage = (medias) => {
    let imageUrl = null;
    if (Array.isArray(medias) && medias[0]) {
      imageUrl = medias[0].mediaThumbnailUrl;
    }
    return imageUrl;
  };

  const methods = validateForm();

  const {
    handleSubmit, register, errors,
  } = methods;
  return (
    <Row className="">
      <Col xs="12">
        {/*  ====== PAGE COMPONENT FORM S[0002]============ */}
        <Form onSubmit={handleSubmit(onSubmit)}>
          {/*  ====== PAGE COMPONENT HEADER S[0001]============ */}
          <fieldset disabled={onSubmitting}>
            <Row className="pageHeader common-page-card">
              <Col xs="12" md="7" className="text-truncate">
                <span className="pageText">
                  {t("product:title_edit")}
                </span>
              </Col>
              <Col xs="12" md="5" className="d-flex justify-content-end">
                <Button type="button" onClick={onCancel} color="secondary" className={clsx(styles.borderRadius2, styles.btn, styles.secondaryColor, "text-truncate", "mr-1")}>
                  {t("common:button_cancel")}
                </Button>
                <Button type="submit" color="secondary" className={clsx(styles.borderRadius2, styles.btn, styles.primaryColor, "text-truncate", "mr-1", "button-font-size")}>
                  {t("common:button_save")}
                </Button>
              </Col>
            </Row>
            {/*  ====== PAGE COMPONENT HEADER E[0001]============ */}

            <div className="common-page-card">
              {/*  ====== PRODUCT FORM E[0005]============ */}
              <ProductForm
                /** form data */
                name={name}
                productOptions={productOptions}
                enableProduct={enableProduct}
                skuName={skuName}
                listPrice={listPrice}
                quantity={quantity}
                onSale={onSale}
                images={images}
                productType={productType}
                selectedCategories={selectedCategories}
                categories={categories}
                attributes={attributes}
                description={description}
                longDescription={longDescription}
                startDate={startDate}
                endDate={endDate}
                landingPageUrl={landingPageUrl}
                register={register}
                errors={errors}
                primaryParentCategory={primaryParentCategory}
                /** events */
                handleFormChange={handleFormChange}
                handleChange={handleChange}
              />
              {/*  ====== PRODUCT FORM E[0005]============ */}
              {/*  ====== SKU FORM E[0008]============ */}
            </div>
            <div className="common-page-card">
              <Row className={clsx(styles.block3, "py-0")}>
                <Col xs="12">
                  <Row>
                    <Col xs="6" className={styles.variantsText}>
                      <span>Variants</span>
                    </Col>
                    <Col xs="6">
                      <div className="w-100 text-right mb-3">
                        <Button
                          className=" text-truncate btn-tertiary btn btn-primary"
                          onClick={() => handleRedirectToModifySku()}
                        >
                          {t("product:button_add_variant")}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <div className="w-100">
                        {
                          Array.isArray(skus) && (
                            <div
                              className="table-responsive"
                            >
                              <Table borderless className={styles.variantTable}>
                                {/* ========= TABLE HEAD S[0003] ======================= */}
                                <thead>
                                  <tr>
                                    <th className="text-left">
                                      {t("product:label_skuId")}
                                    </th>
                                    <th>
                                      {t("product:label_skuName")}
                                    </th>
                                    <th>{t("common:label_image")}</th>
                                    <th>
                                      {t("product:label_external_id")}
                                    </th>
                                    {/* <th>{t("common:label_listPrice")}</th> */}
                                    {/* <th>{t("common:label_quantity")}</th> */}
                                    <th>{t("product:label_enable_disable")}</th>
                                    <th>{t("product:label_enable_actions")}</th>
                                  </tr>
                                </thead>
                                {/* ========= TABLE HEAD S[0003] ======================= */}
                                <tbody>
                                  {/* ========= TABLE BODY S[0002] ======================= */}
                                  {
                                    skus.length > 0 ? skus.map((sku) => (
                                      <tr key={sku.id}>
                                        {/* ======== EACH SKU ITEM S[0003] ============= */}
                                        <td
                                          className={styles.mw75}
                                        >
                                          <span title={sku.id} className={clsx("text-truncate", "d-block")}>
                                            {sku.id}
                                          </span>
                                        </td>
                                        <td className={styles.mw75}>
                                          <span title={sku.name} className={clsx("text-truncate", "d-block")}>
                                            {sku.name}
                                          </span>
                                        </td>
                                        <td className="p-1 align-middle text-center">
                                          <Image src={getPreviewImage(sku.medias)} alt={name} className={clsx(styles.productImage, "img-fluid")} />
                                        </td>
                                        <td className={styles.mw75}>
                                          {sku.uniqueId}
                                        </td>
                                        {/* <td>{sku.listPrice}</td> */}
                                        {/* <td>{sku.quantity || 100}</td> */}
                                        <td>
                                          <div
                                            className={clsx(
                                              "custom-control",
                                              "custom-switch",
                                            )}
                                          >
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id={`expand-multiple${0}`}
                                              checked={sku.active}
                                              onChange={() => { }}
                                            />
                                            <label className="custom-control-label" htmlFor={`expand-multiple${0}`}>
                                              <input type="hidden" />
                                            </label>
                                          </div>
                                        </td>
                                        <td>
                                          <img
                                            className={clsx("commonPointer", styles.icon)}
                                            src={EditIcon}
                                            alt="Edit"
                                            onClick={() => handleEditSku(sku.id)}
                                            role="presentation"
                                          />

                                          <img
                                            className={clsx("commonPointer", styles.icon)}
                                            src={DeleteIcon}
                                            alt="Delete"
                                          />
                                        </td>
                                        {/* ======== EACH SKU ITEM E[0003] =============== */}
                                      </tr>
                                    ))
                                      : (
                                        <tr>
                                          <td colSpan={8} align="center" valign="center"> Nothing to show.</td>
                                        </tr>
                                      )
                                  }
                                  {/* ========= TABLE BODY E[0002] ======================= */}
                                </tbody>
                              </Table>
                            </div>
                          )
                        }
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <div className="common-page-card">
              <Row className={clsx(styles.block3, "py-0")}>
                <Col xs="12" className="px-0">
                  <div className={clsx(styles.variantsText, "px-3")}>
                    <span>Related Products</span>
                  </div>
                  {/*  ====== ADD PRODUCTS S[0003]============ */}
                  {
                    id && (
                      <ProductSelectionContainer
                        onChange={(o) => handleChange("relatedProducts", o)}
                        selectedProductDetails={relatedProductDetails}
                        exclude={[id]}
                      />
                    )
                  }
                  {/*  ====== ADD PRODUCTS E[0003]============ */}
                </Col>
              </Row>
            </div>
            <div className="common-page-card">
              <Row className={clsx(styles.block3, "py-0")}>
                <Col xs="12">
                  <div className="pt-3">
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={clsx(styles.block2, { active: activeTab === "1" })}
                          onClick={() => { toggleTab("1"); }}
                        >
                          Product Details
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink

                          className={clsx({ active: activeTab === "2" })}
                          onClick={() => { toggleTab("2"); }}
                        >
                          External Attributes
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                  {/*  ======RETAILER EXTENSIONS S[0013]============ */}
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <Form onSubmit={onSubmit}>
                        <Row className={clsx(styles.block2, "common-page-card")}>
                          <div className="w-100">
                            <StaticDetailsView
                              properties={productDetails}
                              type="productDetails"
                            />
                          </div>
                        </Row>
                      </Form>
                    </TabPane>
                    <TabPane tabId="2">
                      <Form onSubmit={onSubmit}>
                        <Row className={clsx(styles.block2, "common-page-card")}>
                          <div className="w-100">
                            <StaticDetailsView
                              properties={retailerExtensions}
                              type="retailerExtensions"
                            />
                          </div>
                        </Row>
                      </Form>
                    </TabPane>
                  </TabContent>

                  {/*  ====== RETAILER EXTENSIONS E[0013]============ */}
                </Col>
              </Row>
            </div>
          </fieldset>
          {/*  ====== SET PATENT PRODUCTS E[0004]============ */}
        </Form>
        {/*  ====== PAGE COMPONENT FORM E[0002]============ */}
      </Col>
    </Row>
  );
};

EditProduct.defaultProps = {
  retailerExtensions: null,
  productDetails: null,
};

EditProduct.propTypes = {
  /** form data */
  name: PropTypes.string.isRequired,
  enableProduct: PropTypes.bool.isRequired,
  skuName: PropTypes.string.isRequired,
  listPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onSale: PropTypes.bool.isRequired,
  images: PropTypes.arrayOf(PropTypes.any).isRequired,
  productType: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedCategories: PropTypes.arrayOf(PropTypes.any).isRequired,
  skus: PropTypes.arrayOf(PropTypes.any).isRequired,
  attributes: PropTypes.objectOf(PropTypes.any).isRequired,
  description: PropTypes.string.isRequired,
  longDescription: PropTypes.string.isRequired,
  landingPageUrl: PropTypes.string.isRequired,
  startDate: PropTypes.objectOf(PropTypes.any).isRequired,
  endDate: PropTypes.objectOf(PropTypes.any).isRequired,
  relatedProductDetails: PropTypes.arrayOf(PropTypes.any).isRequired,
  retailerExtensions: PropTypes.objectOf(PropTypes.any),
  productDetails: PropTypes.objectOf(PropTypes.any),
  /** events */
  handleChange: PropTypes.func.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  handleEditSku: PropTypes.func.isRequired,
  handleRedirectToModifySku: PropTypes.func.isRequired,
  /** form event */
  onSubmitting: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  primaryParentCategory: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  productOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default EditProduct;
