/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * page configuration services
 *
 * @author Naseef O
 *
 */

import httpApi from "./httpApi";

/**
 * This method is used to get page configurations
 *
 * @param {*} widgetType
 * @param {*} configId
 * @returns
 */
const getPageConfigurationsByPageType = async (pageType, configId, locale) => {
  const response = await httpApi.get(`/v1/page-configurations/admin/page-type/${pageType}`, { params: { configId, locale } });
  if (response && response.data
    && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to get mobile page configurations
 *
 * @param {*} widgetType
 * @param {*} configId
 * @returns
 */
const getMobilePageConfigurationsByPageType = async (pageType) => {
  const response = await httpApi.get(`/v1/page-configurations/admin/mobile/page-type/${pageType}`);
  if (response && response.data
    && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to get update update a section of page configuration
 *
 * @param {*} widgetType
 * @param {*} configId
 * @returns
 */
const updateSectionConfig = async (requestData, locale) => {
  const params = { locale };
  const response = await httpApi.patch("/v1/page-configurations/section-config", requestData, { params });
  if (response && response.data
    && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to get all custom pages
 *
 * @param {*} searchTerm
 * @param {*} page
 * @param {*} size
 * @returns
 */
const getAllCustomPages = async (
  searchTerm = "",
  page = 0,
  size = 5,
) => {
  const response = await httpApi.get(`/v1/page-configurations/admin/custom-pages?searchTerm=${searchTerm}&page=${page}&size=${size}`);
  if (response && response.data
    && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to get custom page by id
 *
 * @param {*} id
 * @returns
 */
const getCustomPageById = async (id, locale) => {
  const response = await httpApi.get(`/v1/page-configurations/admin/custom-pages/id/${id}?locale=${locale}`);
  if (response && response.data
    && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to get update update a custom page
 *
 * @param {*} requestData
 * @returns
 */
const updateCustomPage = async (requestData, locale) => {
  const params = { locale };
  const response = await httpApi.patch("/v1/page-configurations/admin/custom-pages", requestData, { params });
  if (response && response.data
    && response.data.success) {
    return response.data;
  }
  return null;
};
const toggleCustomLandingPageFlag = async (requestData) => {
  const response = await httpApi.patch("/v1/page-configurations/admin/landing-page-activation", requestData);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to get create a custom page
 *
 * @param {*} requestData
 * @returns
 */
const createCustomPage = async (requestData, locale) => {
  const params = { locale };
  const response = await httpApi.post("/v1/page-configurations/admin/custom-pages", requestData, { params });
  if (response && response.data
    && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to get delete a custom page
 *
 * @param {*} requestData
 * @returns
 */
const deleteCustomPageById = async (id) => {
  const response = await httpApi.delete(`/v1/page-configurations/admin/custom-pages/id/${id}`);
  if (response && response.data
    && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to reorder wdigets
 *
 * @param {*} widgets
 * @param {*} id
 * @returns
 */
const reorderWidgets = async (reorderWidgets) => {
  const response = await httpApi.patch("/v1/page-configurations/reorder", reorderWidgets);
  if (response && response.data
    && response.data.success) {
    return response.data;
  }
  return null;
};

export {
  getPageConfigurationsByPageType,
  updateSectionConfig,
  getAllCustomPages,
  getCustomPageById,
  updateCustomPage,
  getMobilePageConfigurationsByPageType,
  toggleCustomLandingPageFlag,
  createCustomPage,
  deleteCustomPageById,
  reorderWidgets,
};
