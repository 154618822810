// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Copyright(c) 2022 Mozanta Technologies Private Ltd.\n *\n * All rights reserved.\n *\n * This software is the confidential and proprietary information of Mozanta (\"Confidential\n * Information\"). You shall not disclose such Confidential Information and shall use it only in\n * accordance with the terms of the contract agreement you entered into with Mozanta.\n *\n * @author Abdul Razack\n */\n  \n  .ColourPaletteManagement_block2__2j8e3 {\n    padding-top: 20px;\n    padding-bottom: 20px;\n  }\n  \n  ", ""]);
// Exports
exports.locals = {
	"block2": "ColourPaletteManagement_block2__2j8e3"
};
module.exports = exports;
