/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Krishnanunny H
 */

import React, { useState } from "react";
import XLSX from "xlsx";
import PropTypes from "prop-types";
import Sequence from "../components/Sequencing";
/** ========= CUSTOM COMPONENTS ========= */
import AlertModal from "../components/AlertModal";
/** ========= UTILS ========= */
import categoryUtils from "../../../common/utils/categoryUtils";
/** ===== API SERVICE FUNCTIONS =========== */
import { updateProductRankByUniqueIds } from "../../../api/productManagementServices";


const SequencingContainer = (props) => {
  const { categoryId } = props;
  const [modalMattibutes, setModalAttributes] = useState({
    isOpen: false,
    content: "",
    header: "",
  });
  const [loadingFlags, setLoadingFlags] = useState(false);

  /**
   * This method is sued to update product rank
   * @param {String} rankUpdataDetails
   */
  const updateProductRank = (rankUpdataDetails) => {
    updateProductRankByUniqueIds(rankUpdataDetails).then((response) => {
      if (response && response.success === true) {
        setLoadingFlags(false);
      }
    });
  };

  const handleBulkImport = (e) => {
    if (e && e.target && e.target.files) {
      const file = e.target.files[0];
      const fileName = file.name;
      if (!fileName.endsWith(".xlsx") && !fileName.endsWith(".xls")) {
        setModalAttributes({
          isOpen: true,
          content: "Invalid file type. Please upload a XLSX/XLS file",
          header: "Error",
        });
      } else {
        const { name } = e.target;
        const reader = new FileReader();
        reader.onload = async (f) => {
          const data = new Uint8Array(f.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const sheetNameList = workbook.SheetNames;
          const rawData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetNameList[0]], { raw: true });
          const parsedData = categoryUtils.parseDataFromSheet(rawData);
          if (parsedData.isValid) {
            setLoadingFlags(true);
            const sequenceDataFiltered = parsedData.options.map((each) => ({
              categoryId,
              categoryUniqueId: each.CategoryID,
              productUniqueId: each.ProductSKU,
              productRank: each.SequenuceValue,
            }));
            updateProductRank(sequenceDataFiltered);
          } else {
            setModalAttributes({
              isOpen: true,
              content: parsedData.message || "Something went wrong.Please try again",
              header: "Error",
            });
          }
        };
        reader.readAsArrayBuffer(file);
      }
      e.target.value = null;
    }
  };

  const downloadBulkImportTemplate = (name, context) => {
    const fileName = `${name}_template.xlsx`;
    const templateData = categoryUtils.getImportSheetTemplate(context);
    const ws = XLSX.utils.json_to_sheet(templateData);
    const wb = XLSX.utils.book_new();
    ws["!cols"] = [
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
    ];
    XLSX.utils.book_append_sheet(wb, ws, name);
    XLSX.writeFile(wb, fileName);
  };

  return (
    <>
      <Sequence handleBulkImport={handleBulkImport} downloadBulkImportTemplate={downloadBulkImportTemplate} loadingFlags={loadingFlags} />
      <AlertModal
        isOpen={modalMattibutes.isOpen}
        content={modalMattibutes.content}
        header={modalMattibutes.header}
        togglClose={() => setModalAttributes({
          isOpen: false,
          content: "",
          header: "",
        })}
      />
    </>
  );
};

SequencingContainer.propTypes = {
  categoryId: PropTypes.string.isRequired,
};

export default SequencingContainer;
