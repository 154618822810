/**
 * Copyright(c) 2022 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Abdul Razack
 */
import constants from "./constants";
import commonUtils from "./commonUtils";

const { isListNotEmpty } = commonUtils;

const {
  COLOUR_PALETTE_MANAGEMENT: {
    BULK_UPLOAD_LIMIT,
    SHEET_CONTEXT_COLOUR_PALETTE,
    HEADER_KEYS,
    FIELD_VALIDATIONS,
  },
} = constants;

const isValidHeaders = (rawData, header) => {
  const currentHeader = header;
  let result = true;
  if (currentHeader.length === HEADER_KEYS.length) {
    for (let i = 0; i < currentHeader.length; i += 1) {
      if (currentHeader[i].trim() !== HEADER_KEYS[i].trim()) {
        result = false;
        break;
      }
    }
  } else {
    result = false;
  }
  return result;
};

const validateColumnValues = (data, index) => {
  const columnValidationResult = [];
  let errorObj = {};
  Object.entries(FIELD_VALIDATIONS).forEach(async ([key, value]) => {
    if (value.REQUIRED && (!data[value.name] || !(data[value.name] && data[value.name].toString().trim()).length > 0)) {
      errorObj = {};
      errorObj.isError = true;
      errorObj.row = index + 2;
      errorObj.message = `The value of ${value.name} is empty, found in Row: ${index + 2}. Please refer the template for valid data format.`;
      columnValidationResult.push(errorObj);
    } else if (data[value.name] && (data[value.name].toString().trim()).length > value.MAX_LENGTH) {
      errorObj = {};
      errorObj.isError = true;
      errorObj.row = index + 2;
      errorObj.message = `The length of the value in ${value.name} is greater than the ${value.MAX_LENGTH}. Please refer the template for valid data format.`;
      columnValidationResult.push(errorObj);
    }
  });
  return columnValidationResult;
};
const removeWhiteSpaceFromKeys = (data) => {
  const formattedObject = {};
  const keys = Object.keys(data);
  keys.forEach((key) => {
    formattedObject[`${key.trim()}`] = data[key];
  });
  return formattedObject;
};
const validateEntries = async (rawData) => {
  let validationResult = [];
  const formattedData = [];
  const uniqueEntryIds = [];
  let errorObj = {};
  await rawData.forEach(async (data, index) => {
    const whiteSpaceRemovedData = await removeWhiteSpaceFromKeys(data);
    if (uniqueEntryIds.includes(`${whiteSpaceRemovedData["SAP ARTICLE"]}*${whiteSpaceRemovedData["Colors Code"]}`)) {
      errorObj = {};
      errorObj.isError = true;
      errorObj.row = index + 2;
      errorObj.message = `Duplicated entries for SAP ARTICLE: ${whiteSpaceRemovedData["SAP ARTICLE"]} and Colors Code: ${whiteSpaceRemovedData["Colors Code"]} found in Row: ${index + 2}.  Please refer the template for valid data format.`;
      validationResult.push(errorObj);
    } else {
      uniqueEntryIds.push(`${whiteSpaceRemovedData["SAP ARTICLE"]}*${whiteSpaceRemovedData["Colors Code"]}`);
    }
    const columnValidationResult = await validateColumnValues(whiteSpaceRemovedData, index);
    if (columnValidationResult && columnValidationResult.length > 0) {
      validationResult = [...validationResult, ...columnValidationResult];
    }
    formattedData.push(whiteSpaceRemovedData);
  });
  return { validationResult, formattedData };
};


const parseDataFromSheet = async (rawData, header) => {
  if (isListNotEmpty(rawData)) {
    if (!isValidHeaders(rawData, header)) {
      return {
        isValid: false,
        message: "Invalid sheet format. Column headers are mismatched, Please refer the template for valid data format.",
      };
    }
    if (rawData.length > BULK_UPLOAD_LIMIT) {
      return {
        isValid: false,
        message: ` You are uploading ${rawData.length} entries but the maximum allowed limit is ${BULK_UPLOAD_LIMIT}.`,
      };
    }

    return {
      isValid: true,
      // formattedData: validationResponse.formattedData,
    };

    // TODO: BELOW CODE IS COOMENTED TO AVOID FRONT END COLUMN VALIDATION
    // const validationResponse = await validateEntries(rawData);
    // if (validationResponse?.validationResult.length > 0) {
    //   return {
    //     isValid: false,
    //     message: "Invalid values. Please refer the template for valid data format.",
    //     errors: validationResponse.validationResult,
    //   };
    // } else {
    //   return {
    //     isValid: true,
    //     formattedData: validationResponse.formattedData,
    //   };
    // }
  }
  return {
    isValid: false,
    message: "No Data Found",
  };
};


const sheetTemplateMapper = {
  [SHEET_CONTEXT_COLOUR_PALETTE]: [{
    " Colors Arabic": "<Colors Arabic 1>",
    Colors: "<Colors 1>",
    "Colors Code": "<Colors Code 1>",
    Brand: "<Brand 1>",
    Product: "<Product 1>",
    Size: "<Size 1>",
    Base: "<Base 1>",
    "SAP ARTICLE": "<SAP ARTICLE 1>",
    Red: "<Red 1>",
    Green: "<Green 1>",
    Blue: "<Blue 1>",
    "RGB HEX": "<RGB HEX 1>",
  },
  {
    " Colors Arabic": "<Colors Arabic 2>",
    Colors: "<Colors 2>",
    "Colors Code": "<Colors Code 2>",
    Brand: "<Brand 2>",
    Product: "<Product 2>",
    Size: "<Size 2>",
    Base: "<Base 2>",
    "SAP ARTICLE": "<SAP ARTICLE 2>",
    Red: "<Red 2>",
    Green: "<Green 2>",
    Blue: "<Blue 2>",
    "RGB HEX": "<RGB HEX 2>",
  },
  {
    " Colors Arabic": "<Colors Arabic 3>",
    Colors: "<Colors 3>",
    "Colors Code": "<Colors Code 3>",
    Brand: "<Brand 3>",
    Product: "<Product 3>",
    Size: "<Size 3>",
    Base: "<Base 3>",
    "SAP ARTICLE": "<SAP ARTICLE 3>",
    Red: "<Red 3>",
    Green: "<Green 3>",
    Blue: "<Blue 3>",
    "RGB HEX": "<RGB HEX 3>",
  },
  {
    " Colors Arabic": "<Colors Arabic 4>",
    Colors: "<Colors 4>",
    "Colors Code": "<Colors Code 4>",
    Brand: "<Brand 4>",
    Product: "<Product 4>",
    Size: "<Size 4>",
    Base: "<Base 4>",
    "SAP ARTICLE": "<SAP ARTICLE 4>",
    Red: "<Red 4>",
    Green: "<Green 4>",
    Blue: "<Blue 4>",
    "RGB HEX": "<RGB HEX 4>",
  },
  ],
};

const getImportSheetTemplate = (context) => sheetTemplateMapper[context];

export default {
  parseDataFromSheet,
  getImportSheetTemplate,
};
