/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Aardra S
 */

import React, { useState, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

/** ========= SUB COMPONENT ========= */
import EditStore from "../components/EditStore";


/** ========= API SERVICE FUNCTIONS ========= */
import {
  getStoreById, updateStore, getStoreByStoreId, getCities, setShowInStorefrontStatus, getAreas,
} from "../../../api/storeManagementServices";
import { getTranslatedInputsByPage } from "../../../api/translationServices";


const EditStoreContainer = () => {
  const history = useHistory();
  const { params } = useRouteMatch();


  if (!params.storeId) {
    history.push("/administration/store-locations");
  }

  const [form, setForm] = useState({
    id: "",
    name: "",
    events: "",
    services: "",
    showInStorefront: false,
    hdAvailable: false,
    fulfillmentStore: false,
    cncAvailable: false,
    contactInfo: {
      dialCode: "+974", // TODO: Take the value of dialCode from country
    },
    dncAvailable: false,
    pageTitle: "",
    metaDescription: "",
    workingHours: {},
    storeId: "",
    serviceAvilableHours: "",
  });
  const [error, setError] = useState({
    storeId: "",
  });
  const [storeId, setStoreId] = useState("");
  const [onSubmitting, setOnSubmitting] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [selectedDataId, setSelectedDataId] = useState(null);
  const [data, setData] = useState([]);
  const [selectedLanguage, setLanguage] = useState("en_QA");
  const [translatableFields, setSelectedtranslatableFields] = useState([]);
  const [message, setMessage] = useState({
    type: null,
    message: "",
  });
  const [areas, setAreas] = useState([]);


  useEffect(() => {
    let localForm = form;

    getStoreById(params.storeId, selectedLanguage).then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        setStoreId(data.storeId);
        localForm = {
          ...form,
          id: data.id,
          name: data.name,
          events: data.events || "",
          services: data.services || "",
          showInStorefront: data.showInStorefront,
          hdAvailable: data.hdAvailable,
          fulfillmentStore: data.fulfillmentStore,
          dncAvailable: data.dncAvailable,
          cncAvailable: data.cncAvailable,
          contactInfo: { ...data.contactInfo, dialCode: "+974" } || {}, // TODO: Take the value of dialCode from country
          workingHours: data.workingHours || {},
          pageTitle: data.pageTitle || "",
          metaDescription: data.metaDescription || "",
          storeId: data.storeId || "",
          serviceAvilableHours: data.serviceAvilableHours,
        };
        setForm(localForm);
        if (data && data.contactInfo && data.contactInfo.city) {
          getAreas(data.contactInfo.city, selectedLanguage).then((response) => {
            if (response && response.data && response.data.data) {
              setAreas(response.data.data);
            }
          });
        }
      }
    });
  }, [params.storeId, selectedLanguage]);

  /**
   * Method used to get translatable inputs
   */
  useEffect(() => {
    getTranslatedInputsByPage("store").then((response) => {
      if (response && response.data) {
        const translatedInputs = response.data;
        if (translatedInputs) {
          setSelectedtranslatableFields(translatedInputs);
        }
      }
    });
  }, []);

  /**
   * Method used to select Language
   */
  const selectedLanguageChange = (value) => {
    setLanguage(value);
  };

  /**
    * This method is used to change form state
    * @param {String} name
    * @param {String} value
    */
  const handleChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };


  /**
  * This method is used to change error state
  * @param {String} name
  * @param {String} value
  */
  const handleError = (name, value) => {
    setError({
      ...error,
      [name]: value,
    });
  };


  /**
 *  Validate storeId
 */
  const validateStoreId = async () => {
    const response = await getStoreByStoreId(storeId);
    if (response && response.data && (form.storeId !== storeId)) {
      handleError("storeId", "Store Id already exits");
      return false;
    }
    handleError("storeId", "");
    return true;
  };

  /**
* This method is used to change form filed input
* @param {Event} event
*/
  const handleFormChange = (event) => {
    const { target: { name, value } } = event;
    handleError(name, "");
    if (name === "storeId") {
      setStoreId(value);
    } else {
      handleChange(event.target.name, event.target.value);
    }
  };


  /**  This function is to get Area List by City name */
  const updateArea = (cityCode) => {
    if (cityCode) {
      getAreas(cityCode, selectedLanguage).then((response) => {
        if (response && response.data && response.data.data) {
          setAreas(response.data.data);
        }
      });
    }
  };

  /**
    * This method is used to change form filed input
    * @param {Event} event
    */
  const handleFormObjectChange = (parentObject, label, event) => {
    handleChange(event.target.name, { ...parentObject, [label]: event.target.value });
    if (label === "city") {
      updateArea(event.target.value);
    }
  };

  /**
    * This method is used to handle location change
    * @param {Event} event
    */
  const onLocationChange = (location) => {
    const { lat, lng } = location;
    handleChange("contactInfo", { ...form.contactInfo, location: { lat, lng } });
  };

  const validateLocation = () => {
    if (form && form.contactInfo && form.contactInfo.location) {
      return { isLocationValid: true, message: "" };
    }
    const errorMessage = "Please select the store location from the map";
    return { isLocationValid: false, errorMessage };
  };

  /**
    * This method is used to submit the form
    * @param {Event} event
    */
  const onSubmit = async (event) => {
    event.preventDefault();
    const isValid = await validateStoreId();
    if (isValid) {
      const { isLocationValid, errorMessage } = validateLocation();
      if (isLocationValid) {
        const requestBody = {
          id: form.id,
          name: form.name,
          events: form.events,
          services: form.services,
          showInStorefront: form.showInStorefront,
          hdAvailable: form.hdAvailable,
          fulfillmentStore: form.fulfillmentStore,
          dncAvailable: form.dncAvailable,
          cncAvailable: form.cncAvailable,
          contactInfo: form.contactInfo,
          workingHours: form.workingHours,
          pageTitle: form.pageTitle,
          metaDescription: form.metaDescription,
          storeId,
          serviceAvilableHours: form.serviceAvilableHours,
        };

        /** clear the messages */
        setMessage({ type: null, message: "" });
        /** setting on submit true */
        setOnSubmitting(true);
        const response = await updateStore(requestBody, selectedLanguage);
        if (response && response.success) {
          setMessage({ type: "success", message: response.messages[0] });
          setTimeout(() => {
            setOnSubmitting(false);
            history.push("/administration/store-locations");
            setMessage({ type: null, message: "" });
          }, 3000);
        } else {
          setOnSubmitting(false);
          setMessage({ type: "warning", message: "Something went wrong." });
          setTimeout(() => {
            setMessage({ type: null, message: "" });
          }, 3000);
        }
      } else {
        setMessage({ type: "danger", message: errorMessage });
        setTimeout(() => {
          setMessage({ type: null, message: "" });
        }, 3000);
      }
    }
  };

  /**
    * This method is used for cancel the edit form
    */
  const cancelForm = () => {
    history.push("/administration/store-locations");
  };

  /**
    * This method is used to list the cities
    */
  useEffect(() => {
    getCities(selectedLanguage).then((response) => {
      const cityLists = [];
      if (response && response.data && response.data.data) {
        const locations = response.data.data;
        locations.map((location) => {
          const { displayName } = location;
          const { code } = location;
          cityLists.push({ label: displayName, value: code });
        });
        setCityList(cityLists);
      }
    });
  }, [selectedLanguage]);

  /**
   * This method used to change store front status
   * @param {String} storeId
   * @param {Boolean} status
   */
  const changeShowInstoreStatus = async (status, storeId) => {
    if (submitting) return;
    setSubmit(true);
    setSelectedDataId(storeId);
    const response = await setShowInStorefrontStatus(storeId);
    if (response && response.success) {
      const newData = data.map((store) => (store.storeId === storeId
        ? ({ ...store, showInStorefront: status }) : store));
      setData(newData);
    }
    setSelectedDataId(null);
    setSubmit(false);
  };


  return (
    <EditStore
      name={form.name}
      events={form.events}
      services={form.services}
      showInStorefront={form.showInStorefront}
      hdAvailable={form.hdAvailable}
      fulfillmentStore={form.fulfillmentStore}
      dncAvailable={form.dncAvailable}
      cncAvailable={form.cncAvailable}
      contactInfo={form.contactInfo}
      workingHours={form.workingHours}
      pageTitle={form.pageTitle}
      metaDescription={form.metaDescription}
      cancelForm={cancelForm}
      handleChange={handleChange}
      handleFormChange={handleFormChange}
      message={message}
      onSubmitting={onSubmitting}
      onSubmit={onSubmit}
      handleFormObjectChange={handleFormObjectChange}
      onLocationChange={onLocationChange}
      storeId={storeId}
      validateStoreId={validateStoreId}
      error={error}
      cityList={cityList}
      handleShowInstoreStatus={changeShowInstoreStatus}
      submitting={submitting}
      selectedLanguageChange={selectedLanguageChange}
      translatableFields={translatableFields}
      selectedLanguage={selectedLanguage}
      areas={areas}
      serviceAvilableHours={form.serviceAvilableHours}
    />
  );
};

export default EditStoreContainer;
