/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */
import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";

/** ========= CUSTOM COMPONENTS ============== */
import ProductManagement from "../components/ProductManagement";

/** ===== API SERVICE FUNCTIONS =========== */
import { getProducts, setStatus } from "../../../api/productManagementServices";
import {
  getSitePreviewConfigurations, getRunningJobs, replicateCatalogue, getCatalogueReplicationStatus,
} from "../../../api/sitePreviewConfigurationsServices";

/*  Main container of the ProductManagementContainer */
const ProductManagementContainer = () => {
  const history = useHistory();
  /** state variables */
  const [products, setProducts] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [pushToLiveEnabled, setPushToLiveEnabled] = useState(false);
  const [message, setMessage] = useState({
    type: null,
    message: "",
  });
  const paginationLimit = 5;

  const setAlert = (alertData) => {
    setMessage(alertData);
    setTimeout(() => {
      setMessage({ type: null, message: "" });
    }, 3000);
  };

  const [previewSiteUrl, setPreviewSiteUrl] = useState("");
  const [previewEnabled, setPreviewEnabled] = useState(false);
  const [pushToLiveButtonEnabled, setPushToLiveButtonEnabled] = useState(false);
  const [showPushToLiveAlert, setShowPushToLiveAlert] = useState(false);

  useEffect(() => {
    getSitePreviewConfigurations().then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        if (data) {
          setPreviewEnabled(data.previewEnabled || false);
          setPushToLiveEnabled(data.pushToLiveEnabled || false);
          const { previewSiteUrl: localPreviewSiteUrl } = data;
          setPreviewSiteUrl(localPreviewSiteUrl);
          getReplicationStatus();
        }
      }
    });
  }, []);

  /**
   * This method used to change product status
   * @param {String} id
   * @param {Boolean} status
   */
  const changeProductStatus = async (status, id) => {
    if (submitting) return;
    setSubmitting(true);
    const response = await setStatus(id);
    if (response && response.success) {
      const newProducts = Array.isArray(products.content) && products.content.length > 0 && products.content.map((product) => (product.id === id
        ? ({ ...product, active: status }) : product));
      setProducts({ ...products, content: newProducts });
      /** should add a status change toast once the toast functionality implemented */
    }
    setSubmitting(false);
  };

  /**
   * This method is used to list data from server
   * @param {String} localSearchTerm
   * @param {Number} newPage
   */
  const getProductListFromServer = (localSearchTerm, newPage = 0) => {
    let searchValue;
    if (localSearchTerm) {
      searchValue = localSearchTerm.trim();
    }
    /** getting list of products when page load */
    getProducts(searchValue, newPage, paginationLimit).then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        setProducts(data);
        if (data.totalPages > 1) {
          setHasMoreData(true);
        } else {
          setHasMoreData(false);
        }
        setPage(newPage);
      }
    });
  };

  /**
   * This method is used to open new product
   * This method will trigger when pagination button click
   * @param {Number} page
   */
  const getPageData = (direction) => {
    getProductListFromServer(searchTerm, page + direction, paginationLimit);
  };

  /**
   * This method is used to update text in search box
   * @param {Event} event
   */
  const handleSearchTerm = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    if (value && `${value}`.trim().length > 1) {
      getProductListFromServer(value, 0);
    } else getProductListFromServer("", 0);
  };

  /**
   * This method is used to load edit page
   * @param {String} categoryId
   */
  const addNewProduct = () => {
    history.push("/merchandising/product/new");
  };

  /**
   * This method is used to open edit page
   * @param {String} productId
   */
  const editProduct = (productId) => {
    history.push(`/merchandising/product/edit/${productId}`);
  };

  useEffect(() => {
    /** getting list of products when page load */
    getProductListFromServer();
  }, []);

  const initializeData = async () => {
    setSearchTerm("");
    setPage(0);
    setHasMoreData(true);
  };

  const onHandleClear = () => {
    initializeData();
  };

  const handlePushToLive = async () => {
    const runningJobsResponse = await getRunningJobs();
    if (runningJobsResponse) {
      const { data: runningJobs } = runningJobsResponse;
      if (!runningJobs || runningJobs.length === 0) {
        setPushToLiveButtonEnabled(false);
        setShowPushToLiveAlert(true);
        replicateCatalogue().then((response) => {
          if (response && response.success) {
            const alertData = {
              type: "success",
              message: "Products pushed to live successfully. Please refresh the search indexes through Administration > Search Index Management, there may be a small delay for reflecting the same in live.",
            };
            setAlert(alertData);
          } else {
            const alertData = {
              type: "danger",
              message: "Something went wrong. Push to live failed",
            };
            setAlert(alertData);
          }
        });
      } else {
        const alertData = {
          type: "warning",
          message: "An Automated batch job is running in background, Please wait and try again later to make your changes live.",
        };
        setAlert(alertData);
      }
    }
    return null;
  };

  const getReplicationStatus = async () => {
    getCatalogueReplicationStatus().then((response) => {
      if (response
        && response.success
        && ((response.data && response.data.completed) || (!response.data))) {
        setPushToLiveButtonEnabled(true);
        if (showPushToLiveAlert) {
          const alertData = {
            type: "success",
            message: "The last product push to live is successfully completed.",
          };
          setAlert(alertData);
        }
        setShowPushToLiveAlert(true);
      } else {
        setPushToLiveButtonEnabled(false);
        if (showPushToLiveAlert) {
          const alertData = {
            type: "warning",
            message: "Please wait some more time to reflect the changes in live.",
          };
          setAlert(alertData);
        }
        setShowPushToLiveAlert(true);
      }
    }).catch((error) => {
      setPushToLiveButtonEnabled(false);
      setShowPushToLiveAlert(true);
    });
    return null;
  };

  return (
    <ProductManagement
      submitting={submitting}
      changeProductStatus={changeProductStatus}
      getPageData={getPageData}
      addNewProduct={addNewProduct}
      editProduct={editProduct}
      page={page} // should remove
      hasMoreData={hasMoreData} // should remove
      searchTerm={searchTerm} // product search text
      handleSearchTerm={handleSearchTerm}
      onHandleClear={onHandleClear}
      productData={products}
      message={message}
      pushToLiveEnabled={pushToLiveEnabled}
      previewSiteUrl={previewSiteUrl}
      handlePushToLive={handlePushToLive}
      pushToLiveButtonEnabled={pushToLiveButtonEnabled}
      getReplicationStatus={getReplicationStatus}
      previewEnabled={previewEnabled}
    />
  );
};

export default ProductManagementContainer;
