/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

/** ========= TAG COMPONENTS ========= */
/** ========= SUB COMPONENT ========= */
import MenuCreator from "../components/MenuCreator";

/** ========= UTILS ========= */
/** ========= MODULE STYLES ========= */
/** ========= CUSTOM COMPONENTS ========= */
/** ========= API SERVICE FUNCTIONS ========= */
import { addSiteNavigation, getNavigationTypes } from "../../../api/siteNavigationServices";

const MenuCreatorContainer = () => {
  const history = useHistory();

  /** local states */
  const [form, setForm] = useState({
    name: "",
    position: "",
    locale: "en_QA",
    active: true,
  });
  const [onSubmitting, setOnSubmitting] = useState(false);
  const [navigationType, setNavigationType] = useState([]);

  useEffect(() => {
    getNavigationTypes().then((response) => {
      if (response && response.success === true) {
        const { data } = response;
        if (Array.isArray(data)) setNavigationType(data);
      }
    });
  }, []);

  /**
   * This method is used to change fom state
   * @param {String} name
   * @param {String} value
   */
  const handleChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  /**
   * This method is used to change form filed input
   * @param {Event} event
   */
  const handleFormChange = (event) => {
    handleChange(event.target.name, event.target.value);
  };

  /**
   * This method is used to go back to listing page
   */
  const formCancel = () => {
    history.push("/merchandising/navigation");
  };

  /**
   * This method is used to submit the form for add new menu
   * @param {Event} event
   */
  const formSubmit = async (event) => {
    setOnSubmitting(true);
    const response = await addSiteNavigation({
      name: form.name,
      type: form.position,
      active: form.active,
      locale: form.locale,
    });
    if (response && response.success) {
      setTimeout(() => {
        setOnSubmitting(false);
        history.push("/merchandising/navigation");
      }, 3000);
    }
    setOnSubmitting(false);
  };

  return (
    <MenuCreator
      name={form.name}
      position={form.position}
      locale={form.locale}
      active={form.active}
      /** form data */
      positions={navigationType}
      onSubmitting={onSubmitting}
      /** form methods */
      handleFormChange={handleFormChange}
      handleChange={handleChange}
      formSubmit={formSubmit}
      formCancel={formCancel}
    />
  );
};

export default MenuCreatorContainer;
