/**
 * Copyright(c) 2022 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Abdul Razack
 */

import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ===== TAG COMPONENTS =========== */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import FormGroup from "../../../common/core/FormGroup";
import Select from "../../../common/components/Select";
import constants from "../../../common/utils/constants";
/** ======== COMPONENTS */
import ColourPaletteManagementContainer from "../containers/ColourPaletteManagementContainer";
/** ===== MODULE STYLES =========== */
import styles from "../css/ColourPaletteManagement.module.css";

const {
  PRODUCT_BULK_MAINTENANCE: {
    COLOUR_PALETTE_UPLOAD,
  },
} = constants;

const ProductBulkManagement = ({
  maintenanceType, maintenanceTypes, handleMaintenanceType,
}) => {
  const { t } = useTranslation(["common", "product-bulk-maintenance"]);

  return (
    <>
      <Row className="pageHeader common-page-card">
        <Col xs="12" md="6" lg="5" className="text-truncate">
          <span className="pageText">{t("product-bulk-maintenance:title_product_bulk_maintenance")}</span>
        </Col>
      </Row>
      <Row className={clsx(styles.block2, "common-page-card")}>
        <Col xs="12" sm="6">
          <FormGroup>
            <span
              className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}
            >
              {t("product-bulk-maintenance:sub_title_maintenance_type")}
            </span>
            <Select
              className="formText mt-2"
              placeholder="Select maintenance type"
              id="maintenanceType"
              value={maintenanceType.name}
              onChange={(e) => handleMaintenanceType(e.target.value)}
              options={[
                ...maintenanceTypes.map((MaintenanceType) => (
                  { value: MaintenanceType.code, label: MaintenanceType.name })),
              ]}
            />
          </FormGroup>
        </Col>
      </Row>
      {maintenanceType.code === COLOUR_PALETTE_UPLOAD && <ColourPaletteManagementContainer />}
    </>
  );
};
ProductBulkManagement.propTypes = {
  maintenanceType: PropTypes.string.isRequired,
  maintenanceTypes: PropTypes.objectOf(PropTypes.any).isRequired,
  handleMaintenanceType: PropTypes.func.isRequired,
};
export default ProductBulkManagement;
