/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */
import moment from "moment";
import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

/** ======== CUSTOM COMPONENTS ========= */
import EditCategory from "../components/EditCategory";

/** ========= SUB COMPONENT ========= */
import ConfirmationModal from "../../../common/components/ConfirmationModal";

/** ===== API SERVICE FUNCTIONS =========== */
import {
  getCategoryDetails,
  updateCategory,
  deleteLeafCategory,
  facetConfig,
  updateFacetConfig,
  getSearchConfigBySort,
  getCategoriesByParenCategoryId
} from "../../../api/categoryManagementServices";

import { getTranslatedInputsByPage, getTranslationConfig } from "../../../api/translationServices";
import constants from "../../../common/utils/constants";

/** Base container for edit new category  */
const EditCategoryContainer = () => {
  const history = useHistory();
  /** reading category id from url params */
  const { categoryId } = useParams();

  /** local states */
  const [form, setForm] = useState({
    id: "",
    name: "",
    description: "",
    longDescription: "",
    navPosition: "",
    startDate: new Date(),
    endDate: new Date(),
    status: false,
    childProducts: [],
    childProductDetails: [],
    bannerImage: [],
    image: [],
    iconUrl: [],
    uniqueId: "",
    type: "",
    source: "",
    navigable: false,
  });

  const [selectedLanguage, setLanguage] = useState();
  const [isDefault, setIsDefault] = useState(true);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [parentCategory, setParentCategory] = useState("");
  const [onSubmitting, setOnSubmitting] = useState(false);
  const [message, setMessage] = useState({
    type: null,
    message: "",
  });
  const [deleteCategory, setDeleteCategory] = useState(false);
  const [translatableFileds, setSelectedtranslatableFiledsect] = useState([]);
  const [sortRuleDropdownOpen, setSortRuleDropdownOpen] = useState(false);
  const [updatFacetConfig, setUpdatFacetConfig] = useState([]);
  const [code, setCode] = useState("Facet");
  const [facet, setFacet] = useState(null);
  const [sortdefaultConfiguration, setSortdefaultConfiguration] = useState([]);
  const [sortOptions, setSortOption] = useState([]);
  const [defaultSortParam, setDefaultSortParam] = useState("");
  const [selectedSort, setSelectedSort] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [categoryFacet, setCategoryFacet] = useState([]);
  const [page, setCurrentPage] = useState(['category']);

  useEffect(() => {
    getSearchConfigBySort(selectedLanguage).then((response) => {
      if (response && response.success === true && response.data) {
        const { defaultConfiguration } = response.data;
        const options = [];
        setSortdefaultConfiguration(defaultConfiguration);
        if (Array.isArray(defaultConfiguration)) {
          defaultConfiguration.map((item) => {
            const option = {
              value: item.field,
              label: item.field,
            };
            options.push(option);
          });
          setSortOption(options);
        }
      }
    });
  }, [selectedLanguage]);


  const sortRuleOnchange = (e) => {
    const selectedSortValue = e.target.value;
    if (Array.isArray(sortdefaultConfiguration)) {
      sortdefaultConfiguration.map((item) => {
        if (item.field === selectedSortValue) {
          let sortString;
          item.elements.map((element, index) => {
            const sortValue = `${element.toString()},${item.direction[index]}`;
            sortString = index === 0 ? sortValue : `${sortString}&${sortValue}`;
          });
          setDefaultSortParam(sortString);
          setSelectedSort(item.field);
        }
      });
    }
  };

  useEffect(() => {
    if (Array.isArray(sortdefaultConfiguration)) {
      sortdefaultConfiguration.map((item) => {
        let sortString;
        item.elements.map((element, index) => {
          const sortValue = `${element.toString()},${item.direction[index]}`;
          sortString = index === 0 ? sortValue : `${sortString}&${sortValue}`;
        });
        if (sortString === defaultSortParam) {
          setSelectedSort(item.field);
        }
      });
    }
  }, [sortdefaultConfiguration, defaultSortParam]);

  const getFacet = () => {
    facetConfig(code, selectedLanguage).then((response) => {
      const customConfig = [];
      if (response && response.success) {
        const { data } = response;
        const newConfig = JSON.parse(JSON.stringify(data));
        if (Array.isArray(newConfig.defaultConfiguration)) {
          newConfig.defaultConfiguration.forEach((config) => {
            if (config.level === "category") {
              config.active = Array.isArray(config.elements) ? config.elements.includes(categoryId) : [];
            } else if (config.level === "global") {
              config.active = Array.isArray(config.excludedElementList) ? !config.excludedElementList.includes(categoryId) : [];
            }
            customConfig.push(config);
          });
        }
        setFacet(data);
        setUpdatFacetConfig(data.defaultConfiguration);
        setCategoryFacet(customConfig);

      }
    });
  };
  useEffect(() => {
    getFacet();
    getChildCategory();
    getCategoryDetails(categoryId, selectedLanguage).then((response) => {
      if (response && response.success === true) {
        const { data } = response;
        if (data) {
          setForm({
            id: data.id,
            name: data.name,
            description: data.description || "",
            longDescription: data.longDescription || "",
            startDate: new Date(data.startDate),
            endDate: new Date(data.endDate),
            status: data.active,
            navPosition: data.navPosition,
            uniqueId: data.uniqueId,
            childProducts: data.childProducts || [],
            childProductDetails: Array.isArray(data.childProductDetails) && data.childProductDetails,
            bannerImage: data.bannerImages ? data.bannerImages.map((largeMediaUrl) => ({ largeMediaUrl })) : null,
            image: data.medias || [],
            iconUrl: data.iconUrl,
            parentCategoryName: data.parentCategoryDetail && data.parentCategoryDetail.name,
            type: data.type,
            source: data.source,
            navigable: data.navigable,
          });
          setSelectedCategories(
            data.childCategories ? data.childCategories : [],
          );
          if (data.defaultSortParam) {
            setDefaultSortParam(data.defaultSortParam);
          } else {
            setDefaultSortParam("");
            setSelectedSort("");
          }
        }
      }
    });
  }, [categoryId, selectedLanguage]);



  /**
   * This method used to change product status
   * @param {String} id
   * @param {Boolean} status
   */
  const changeProductStatus = async (active, field, level, o) => {
    const facetData = facet;
    const mainData = updatFacetConfig;
    const data = mainData.filter((item) => item.field === field);
    if (active) {
      if (level === "category") {
        data[0].elements = data[0] && Array.isArray(data[0].elements) ? data[0].elements.concat(categoryList) : [];
      }
      else if (level === "global") {
        data[0].excludedElementList = data[0] && Array.isArray(data[0].excludedElementList) ? data[0].excludedElementList.filter(x => !categoryList.includes(x)) : [];
      }
    } else {
      if (level === "category") {
        data[0].elements = data[0] && Array.isArray(data[0].elements) ? data[0].elements.filter(x => !categoryList.includes(x)) : [];
      }
      else if (level === "global") {
        data[0].excludedElementList = data[0] && Array.isArray(data[0].excludedElementList) ? data[0].excludedElementList.concat(categoryList) : [];
      }
    }
    mainData.forEach((item) => {
      if (item.field === field) {
        item.elements = data[0]?.elements;
        item.excludedElementList = data[0]?.excludedElementList;
      }
    });
    facetData.defaultConfiguration = mainData;
    updateFacetConfig({
      code: "Facet",
      locale: selectedLanguage || constants.LOCALE.EN_QA,
      defaultConfiguration: facetData.defaultConfiguration,
    }).then((response) => {
      if (response && response.success) {
        getFacet();
      }
    });
  };
  /**
   * This method is used to change fom state
   * @param {String} name
   * @param {String} value
   */
  const handleChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  /**
   * This method is used to change form filed input
   * @param {Event} event
   */
  const handleFormChange = (event) => {
    const { value, name } = event.target;
    if (name === "iconUrl") {
      const tmpIconImage = Array.isArray(value)
        ? value.map(({ largeMediaUrl }) => largeMediaUrl)
        : [value.largeMediaUrl];
      handleChange(name, tmpIconImage[0]);

    } else {
      handleChange(name, value);
    }
  };

  /**
   * This method triggers when a subcategory selected or deselected
   * @param {Array} categories
   */
  const selectedCategoryChange = (categories) => {
    setSelectedCategories(categories);
  };

  /**
   * This method is used to set selected product
   * @param {Array} products
   */
  const selectedProductChange = (products) => {
    handleChange("childProducts", products);
  };

  /**
   * This method is used to change parent category
   * @param {Array} categories
   */
  const handleParentCategory = (categories) => {
    setParentCategory(categories);
  };

  /**
   * Clear button trigger
   */
  const cancelForm = () => {
    history.push("/merchandising/category");
  };

  /**
   * This method is used to submit the form
   * @param {Event} event
   */
  const submitForm = async (event) => {
    let tmpBannerImage = [];
    let tmpIconImage = [];
    if (form.bannerImage) {
      tmpBannerImage = Array.isArray(form.bannerImage)
        ? form.bannerImage.map(({ largeMediaUrl }) => largeMediaUrl)
        : [form.bannerImage.largeMediaUrl];
    }

    const dataToServer = {
      ...form,
      navPosition: form.navPosition,
      startDate: moment(form.startDate).format("YYYY-MM-DD HH:mm:ss"),
      endDate: moment(form.endDate).format("YYYY-MM-DD HH:mm:ss"),
      medias: [...(Array.isArray(form.image) ? form.image : [form.image])],
      bannerImages: tmpBannerImage,
      iconUrl: form.iconUrl && form.iconUrl.largeMediaUrl ? form.iconUrl.largeMediaUrl : form.iconUrl,
      parentCategory,
      childCategories: selectedCategories,
      defaultSortParam,
      type: form.type,
      navigable: form.navigable,
    };
    setMessage({ type: null, message: "" });
    setOnSubmitting(true);
    /** now passing object itself into service */
    const response = await updateCategory(dataToServer, selectedLanguage);
    if (response && response.success) {
      setMessage({ type: "success", message: response.messages[0] });
      setTimeout(() => {
        setOnSubmitting(false);
        history.push("/merchandising/category");
        setMessage({ type: null, message: "" });
      }, 3000);
    } else {
      setOnSubmitting(false);
      setMessage({ type: "warning", message: "Something went wrong." });
      setTimeout(() => {
        setMessage({ type: null, message: "" });
      }, 3000);
    }
  };

  /**
   * This method is used to cancel confirm form
   */
  const cancelConfirm = () => {
    setDeleteCategory(null);
  };

  /**
   * This method is used to delete the category
   */
  const handleDeleteCategory = () => {
    setDeleteCategory(true);
  };

  /**
   * method for delete confirm
   */
  const confirmDelete = async () => {
    if (deleteCategory && categoryId) {
      const response = await deleteLeafCategory(categoryId);
      if (response && response.success === true) {
        history.push("/merchandising/category");
      }
    }
  };

  const toggleSortRuleDropdown = () => {
    setSortRuleDropdownOpen(!sortRuleDropdownOpen);
  };

  const handleSortRuleDropdownSelection = (event) => {
    handleChange("sortRule", event.target.innerText);
    toggleSortRuleDropdown();
  };

  const getChildCategory = () => {
    getCategoriesByParenCategoryId(categoryId).then((response) => {

      if (response && response.data) {
        response.data.forEach(element => {
          categoryList.push(element.id);
          categoryList.push(...element.childCategories);
        });
      }
    });
    categoryList.push(categoryId);
    setCategoryList(categoryList);
  }

  const localeHandler = (localeLang, defaultIn, translatableProperties) => {
    setLanguage(localeLang);
    setSelectedtranslatableFiledsect(translatableProperties);
    setIsDefault(defaultIn);
  };

  return (
    <>
      <EditCategory
        // form field
        id={form.id}
        name={form.name}
        description={form.description}
        longDescription={form.longDescription}
        startDate={form.startDate}
        endDate={form.endDate}
        status={form.status}
        navPosition={form.navPosition}
        type={form.type}
        source={form.source}
        childProducts={form.childProducts}
        childProductDetails={form.childProductDetails}
        bannerImage={form.bannerImage}
        image={form.image}
        iconUrl={form.iconUrl}
        changeProductStatus={changeProductStatus}
        handleSortRuleDropdownSelection={handleSortRuleDropdownSelection}
        toggleSortRuleDropdown={toggleSortRuleDropdown}
        disableDelete={
          (Array.isArray(selectedCategories)
            && selectedCategories.length > 0)
          || (Array.isArray(form.childProducts) && form.childProducts.length > 0)
        }
        // for showing success message
        message={message}
        // disable text filed while form submitting
        onSubmitting={onSubmitting}
        // input filed handle change
        handleFormChange={handleFormChange}
        handleChange={handleChange}
        // form actions
        submitForm={submitForm}
        cancelForm={cancelForm}
        handleDeleteCategory={handleDeleteCategory}
        // categories
        selectedCategories={selectedCategories}
        // parent category
        parentCategory={parentCategory}
        handleParentCategory={handleParentCategory}
        parentCategoryName={form.parentCategoryName}
        // sub category
        selectedCategoryChange={selectedCategoryChange}
        // handle product
        selectedProductChange={selectedProductChange}
        // language select
        selectedLanguage={selectedLanguage}
        localeHandler={localeHandler}
        isDefault={isDefault}
        translatableFileds={translatableFileds}
        facet={categoryFacet}
        sortOptions={sortOptions}
        sortRuleOnchange={sortRuleOnchange}
        selectedSort={selectedSort}
        // toggle Show in Site Navigation
        navigable={form.navigable}
        locale={selectedLanguage}
      />
      <ConfirmationModal
        isOpen={deleteCategory}
        toggleOpen={cancelConfirm}
        togglClose={cancelConfirm}
        handleConfirm={confirmDelete}
        content=" Are you sure you want to delete this category ?"
      />
    </>
  );
};

export default EditCategoryContainer;
