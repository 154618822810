/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Page Customization Container
 *
 * @author Naseef O
 *
 */

import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import qs from "query-string";
import PageCustomization from "../components/PageCustomization";
import { PageTypes } from "../../../common/utils/layoutUtils";
import {
  getPageConfigurationsByPageType,
  updateSectionConfig,
  toggleCustomLandingPageFlag,
  reorderWidgets,
} from "../../../api/pageConfigurationsServices";
import commonUtils from "../../../common/utils/commonUtils";
import constants from "../../../common/utils/constants";
import { getSeoInformation, getCategoryDetails } from "../../../api/categoryManagementServices";
import { getAllBrands } from "../../../api/brandServices";
import {
  getSitePreviewConfigurations,
  replicateContent,
  getContentReplicationStatus,
} from "../../../api/sitePreviewConfigurationsServices";
import { getWidgetConfigurationsByPageType } from "../../../api/widgetConfigurationsServices";
import { getPageLayouts, updatePageLayouts } from "../../../api/LayoutServices";

const PageCustomizationContainer = () => {
  const pages = [
    { name: "Global", pageType: PageTypes.GLOBAL, previewPath: "/" },
    { name: "Home", pageType: PageTypes.HOME, previewPath: "/" },
    { name: "PLP", pageType: PageTypes.PLP, previewPath: "/product-list" },
    { name: "CLP", pageType: PageTypes.CLP, previewPath: "/category-details" },
    { name: "PDP", pageType: PageTypes.PDP, previewPath: "/product-details" },
    {
      name: "My account",
      pageType: PageTypes.MYACCOUNT,
      previewPath: "/myaccount",
    },
    {
      name: "Wishlist",
      pageType: PageTypes.WISHLIST,
      previewPath: "/wishlist",
    },
    {
      name: "Delivery details",
      pageType: PageTypes.DELIVERY_DETAILS,
      previewPath: "/delivery-details",
    },
    { name: "Cart", pageType: PageTypes.CART, previewPath: "/cart" },
    { name: "Payment", pageType: PageTypes.PAYMENT, previewPath: "/payment" },
    {
      name: "Order confirmation",
      pageType: PageTypes.ORDER_CONFIRMATION,
      previewPath: "/order-confirmation",
    },
    { name: "Login", pageType: PageTypes.LOGIN, previewPath: "/login" },
    { name: "Sign up", pageType: PageTypes.SIGNUP, previewPath: "/signup" },
    {
      name: "Password reset",
      pageType: PageTypes.PASSWORD_RESET,
      previewPath: "/password-reset",
    },
    { name: "BLP", pageType: PageTypes.BLP, previewPath: "/brand-details" },
    {
      name: "Track your order",
      pageType: PageTypes.TRACK_YOUR_ORDER,
      previewPath: "/track-your-order",
    },
    { name: "404", pageType: PageTypes.NOTFOUND, previewPath: "/404" },
  ];
  const location = useLocation();
  const { categoryId, pageType } = commonUtils.getQueryParams(location);
  let defaultPage = {
    name: "Global",
    pageType: PageTypes.GLOBAL,
    previewPath: "/global-layouts",
  };
  if (pageType) {
    defaultPage = pages.find((page) => page.pageType === pageType);
  }
  const history = useHistory();

  const [pageConfiguration, setPageConfiguration] = useState(null);
  const [seoInformation, setSeoInformation] = useState(null);
  const [selectedPage, setSelectedPage] = useState(defaultPage);
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [parentCategory, setParentCategory] = useState(categoryId || "");
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [dropdownBrand, toggleDropdownBrand] = useState(false);
  const [customPageEnabled, setCustomPageEnabled] = useState(false);
  const [globalLayout, setGlobalLayouts] = useState(null);
  const [categoryLabel, setCategoryLabel] = useState(null);
  const [layouts, setLayouts] = useState([]);
  const [selectedLayout, setSelectedLayout] = useState(null);
  const [dropdownLayout, setDropdownLayout] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [screenWidgets, setScreenWidgets] = useState([]); /** Stores widgets without the sections like: [{},{}] */
  const [widgetConfigurations, setWidgetConfigurations] = useState(null);
  const [widgetSections, setWidgetSections] = useState([]); /** Stores widget sections eg: ["USP","PRIMARY"] */
  const [newlyActivatedWidgetType, setNewlyActivatedWidgetType] = useState(null);
  const [dropdownLanguage, toggleDropdownLanguage] = useState(false);
  const [locale, setLocale] = useState(constants.LOCALE.EN_QA);
  const [filteredBrands,setFilteredBrands] = useState([]);

  const reorderTranslations = async () => {
    Object.entries(pageConfiguration.translations).forEach(([localeKey, localeConfiguration]) => {
      if (localeKey !== constants.LOCALE.EN_QA && localeConfiguration && localeConfiguration.widgets) {
        const updatedTranslationWidgets = {};
        Object.entries(localeConfiguration.widgets).forEach(([section, widgetTranslation]) => {
          if (widgetSections.indexOf(section) >= 0) {
            /** Checking whether the widgets exists in page configuration and the corresponding widgets in screenWidgets */
            if (pageConfiguration.widgets && screenWidgets[widgetSections.indexOf(section)]) {
              /** Checking whether the widget position changed or not */
              if (pageConfiguration.widgets[section].widgetType !== screenWidgets[widgetSections.indexOf(section)].widgetType) {
                /** `newIndex` gives the updated widget position */
                const newIndex = screenWidgets.findIndex((data) => pageConfiguration.widgets[section].widgetType === data.widgetType);
                if (newIndex >= 0) {
                  /** Store the translation widget with new section name in `updatedTranslationWidgets` */
                  updatedTranslationWidgets[widgetSections[newIndex]] = widgetTranslation;
                }
              } else {
                /** Store the translation widgets which are not changed its position */
                updatedTranslationWidgets[section] = widgetTranslation;
              }
            }
          }
        });
        /** Updating the reordered translations in `pageConfiguration` */
        pageConfiguration.translations[localeKey].widgets = updatedTranslationWidgets;
      }
    });
  };

  /**
  * This method is used to save the reordered widgets in server
  * @param {*} id
  * @param {*} widgets
  * @param {*} translations
  *
  */
  const saveReorderedWidgets = async (id, widgets, translations) => {
    await reorderWidgets(
      {
        id,
        widgets,
        translations,
      },
    ).then((reorderResponse) => {
      if (reorderResponse && reorderResponse.success) {
        setIsLoading(false);
        getPageConfigurationsByPageType(
          selectedPage.pageType, pageConfiguration.id, locale,
        ).then((responseIn) => {
          if (responseIn && responseIn.success && responseIn.data) {
            setPageConfiguration(responseIn.data);
            getPageLayouts(selectedPage.pageType).then((layoutResponse) => {
              if (layoutResponse && layoutResponse.success) {
                setLayouts(layoutResponse.data);
              }
            });
          }
        });
        setIsLoading(true);
      }
    });
  };
  const reorderWidgetsList = async () => {
    if (pageConfiguration) {
      if (pageConfiguration.translations) {
        reorderTranslations();
      }
      /** `defaultLocalePageconfiguration` is used to store the page configuration in the default locale (ie, "en_QA") */
      /** `pageCofigurationToUpdate` is used to send the updated page configuration to server */
      let defaultLocalePageconfiguration = null;
      let pageCofigurationToUpdate = null;
      const defaultLocaleWidgetTypeMap = new Map();
      /** Checking the current `locale` is not "en_QA" */
      if (locale !== constants.LOCALE.EN_QA) {
        /** Fetch the page configuration with locale ("en_QA") */
        await getPageConfigurationsByPageType(
          selectedPage.pageType, pageConfiguration.id, constants.LOCALE.EN_QA,
        ).then((response) => {
          if (response && response.success && response.data) {
            defaultLocalePageconfiguration = response.data;
            pageCofigurationToUpdate = JSON.parse(JSON.stringify(defaultLocalePageconfiguration));
            /** Setting the widgets with corresponding widgetSections in `defaultLocaleWidgetTypeMap` */
            Object.entries(defaultLocalePageconfiguration.widgets).forEach(([section, widget]) => {
              defaultLocaleWidgetTypeMap.set(widget.widgetType, widget);
            });
          }
        });

        Object.entries(pageConfiguration.widgets).forEach(([section, widget]) => {
          const index = widgetSections.indexOf(section);
          if (index >= 0) {
            /** Updating the widgets of `pageCofigurationToUpdate` with corresponding widget present in `screenWidgets` */
            pageCofigurationToUpdate.widgets[section] = defaultLocaleWidgetTypeMap.get(screenWidgets[index].widgetType);
            pageConfiguration.widgets[section] = screenWidgets[index];
          }
        });
        /** Setting the translations */
        pageCofigurationToUpdate.translations = pageConfiguration.translations;
      } else {
        Object.entries(pageConfiguration.widgets).forEach(([section, widget]) => {
          const index = widgetSections.indexOf(section);
          if (index >= 0) {
            pageConfiguration.widgets[section] = screenWidgets[index];
          }
        });
      }
      setIsLoading(true);
      /** Send data to the server */
      await saveReorderedWidgets(pageConfiguration.id, pageCofigurationToUpdate ? pageCofigurationToUpdate.widgets : pageConfiguration.widgets, pageConfiguration.translations);
      return true;
    }
    return false;
  };
  const parseWidgetsObjecttoList = () => {
    const widgetsList = [];
    const keyList = [];

    if (pageConfiguration) {
      const widgetConfigObject = {};
      if (widgetConfigurations) {
        widgetConfigurations.forEach((element) => {
          widgetConfigObject[element.widgetType] = element;
        });
      }

      if (
        pageConfiguration.widgets !== null
         && pageConfiguration.widgets !== undefined
      ) {
        Object.entries(pageConfiguration.widgets).map(([key, value]) => {
          if (
            !constants.ESCAPABLE_WIDGETS.includes(value.widgetType)
             && value.active === true
          ) {
            let tempList = [];
            tempList = value;
            widgetsList.push(tempList);
            keyList.push(key);
          }
        });
      }
    }

    if (newlyActivatedWidgetType !== null) {
      const index = keyList.indexOf(newlyActivatedWidgetType);
      if (index >= 0) {
        widgetsList.push(widgetsList.splice(index, 1)[0]);
      }
    }

    setScreenWidgets(widgetsList);
    setWidgetSections(keyList);
  };

  useEffect(() => {
    if (selectedPage && selectedPage.pageType) {
      setIsLoading(false);
      getPageLayouts(selectedPage.pageType).then((response) => {
        if (response && response.success) {
          setLayouts(response.data);
        }
        setIsLoading(true);
      });
    }
  }, [selectedPage]);

  const toggleDropdownLayout = () => {
    setDropdownLayout(!dropdownLayout);
  };

  useEffect(() => {
    if (Array.isArray(layouts) && layouts.length > 0) {
      if (pageConfiguration) {
        const { layoutId } = pageConfiguration;
        const layout = layouts.find((item) => item.layoutId === layoutId);
        if (layout) {
          setSelectedLayout(layout);
        } else {
          setSelectedLayout(layouts[0]);
        }
      } else {
        setSelectedLayout(layouts[0]);
      }
    }
  }, [layouts]);


  console.warn({ location, history });

  useEffect(() => {
    if (location && location.search) {
      const serachObject = qs.parse(location.search);
      if (serachObject && serachObject.locale) {
        setLocale(serachObject.locale);
      }
    }
  }, [location]);

  const [message, setMessage] = useState({
    type: null,
    message: "",
  });

  const setAlert = (alertData) => {
    setMessage(alertData);
    setTimeout(() => {
      setMessage({ type: null, message: "" });
    }, 3000);
  };

  /**
     * This method is used to get page configurations
     *
     * @returns
     */
  const getPageConfigurations = async () => {
    const response = await getPageConfigurationsByPageType(selectedPage.pageType, null, locale);
    if (response && response.success && response.data) {
      setPageConfiguration(response.data);
      return true;
    }
    setPageConfiguration(null);

    return false;
  };

  const handleChangeClpLayout = (configId, catId, newLayout) => {
    updatePageLayouts(
      selectedLayout.subType,
      selectedLayout.layoutId,
      configId,
      catId,
      newLayout,
    ).then((response) => {
      if (response && response.success && response.data) {
        setParentCategory(catId);
        getPageConfigurationsByPageType(
          selectedPage.pageType,
          configId,
          locale,
        ).then((responseIn) => {
          if (responseIn && responseIn.success && responseIn.data) {
            setPageConfiguration(responseIn.data);
          }
          setIsLoading(true);
        });
      }
      setIsLoading(true);
    });
  };

  const handleChangeLayout = (item) => {
    setIsLoading(false);
    if (selectedPage && selectedPage.pageType !== "CLP") {
      updatePageLayouts(item.subType, item.layoutId, item.configId).then((response) => {
        if (response && response.success) {
          setSelectedLayout(item);
          getPageConfigurations().then((responseIn) => {
            if (responseIn) {
              setIsLoading(true);
            } else {
              setIsLoading(true);
            }
          });
        }
      });
    } else {
      setSelectedLayout(item);
      setParentCategory("");
      setCategoryLabel("");
      setIsLoading(true);
    }
    toggleDropdownLayout();
  };

  useEffect(() => {
    if (selectedPage && selectedPage.pageType !== "CLP") {
      getPageConfigurations().then((response) => {
        if (response) {
          setIsLoading(true);
        } else {
          setIsLoading(true);
        }
      });
    } else if (selectedLayout && parentCategory) {
      getCategoryDetails(parentCategory).then((response) => {
        if (response && response.success && response.data) {
          const { data: { landingPageConfigId, customLandingPageConfigured } } = response;
          setCustomPageEnabled(customLandingPageConfigured);
          if (!landingPageConfigId) {
            handleChangeClpLayout("", parentCategory);
          } else {
            handleChangeClpLayout(landingPageConfigId, parentCategory);
          }
        }
      });
    }
  }, [selectedPage, parentCategory, locale]);

  useEffect(() => {
    if (selectedPage && selectedPage.pageType !== "GLOBAL") {
      getPageConfigurationsByPageType("GLOBAL", null, locale).then((response) => {
        if (response && response.success) {
          setGlobalLayouts(response.data);
        }
      });
    } else {
      setGlobalLayouts({ ...pageConfiguration });
    }
  }, [pageConfiguration]);

  useEffect(() => {
    getWidgetConfigurationsByPageType(selectedPage.pageType, "web").then(
      (response) => {
        if (response && response.success && response.data) {
          setWidgetConfigurations(response.data);
          setIsLoading(true);
        }
      },
    );
  }, [selectedPage]);

  useEffect(() => {
    if (selectedPage.pageType === PageTypes.BLP) {
      setIsLoading(false);
      getAllBrands().then((response) => {
        if (response && response.success && response.data) {
          const { data } = response;
          setBrands(data);
          if (Array.isArray(data) && data.length > 0) {
            const sortedBrandList = data.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
            setFilteredBrands(sortedBrandList);
          }
          if (parentCategory) {
            setIsLoading(true);
            const brand = data.find((item) => item.id === parentCategory);
            setSelectedBrand(brand);
            setParentCategory(parentCategory);
            setCustomPageEnabled(
              brand ? brand.customLandingPageConfigured : false,
            );
          } else {
            setIsLoading(true);
            setSelectedBrand(data[0] ? data[0] : null);
            setParentCategory(data[0] ? data[0].id : null);
            setCustomPageEnabled(
              data[0] ? data[0].customLandingPageConfigured : false,
            );
          }
        } else {
          setIsLoading(true);
        }
      });
    }
  }, [selectedPage]);

  useEffect(() => {
    if (categoryId) {
      setIsLoading(false);
      getCategoryDetails(categoryId).then((response) => {
        if (response && response.success && response.data) {
          const {
            data: { name, customLandingPageConfigured, landingPageConfigId },
          } = response;

          setCategoryLabel(name);
          setCustomPageEnabled(customLandingPageConfigured);
          getPageConfigurationsByPageType(
            selectedPage.pageType,
            selectedPage.pageType === "CLP" ? landingPageConfigId : "",
            locale,
          ).then((responseIn) => {
            if (responseIn && responseIn.success && responseIn.data) {
              setPageConfiguration(responseIn.data);
            }
            setIsLoading(true);
          });
        } else {
          setIsLoading(true);
        }
      });
    }
  }, [categoryId]);

  useEffect(() => {
    if (parentCategory) {
      getSeoInformation(parentCategory, locale).then((response) => {
        if (response && response.success && response.data) {
          const { data } = response;
          setSeoInformation(data);
        }
      });
    } else {
      setSeoInformation(null);
    }
  }, [selectedPage, parentCategory, locale]);

  useEffect(() => {
    if (selectedPage && (selectedPage.pageType === "CLP" || selectedPage.pageType === "HOME")) {
      parseWidgetsObjecttoList();
    }
  }, [pageConfiguration, widgetConfigurations]);

  useEffect(() => {
    if (selectedPage && (selectedPage.pageType === "CLP" || selectedPage.pageType === "HOME")) {
      if (newlyActivatedWidgetType) {
        reorderWidgetsList();
        setNewlyActivatedWidgetType(null);
      }
    }
  }, [widgetSections, screenWidgets]);


  const [isEdit, setIsEdit] = useState(false);
  const [selectedWidget, setSelectedWidget] = useState(null);
  const [widgetTitle, setWidgetTitle] = useState("");
  const [previewSiteUrl, setPreviewSiteUrl] = useState("");
  const [previewEnabled, setPreviewEnabled] = useState(false);
  const [pushToLiveEnabled, setPushToLiveEnabled] = useState(false);
  const [pushToLiveButtonEnabled, setPushToLiveButtonEnabled] = useState(false);
  const [showPushToLiveAlert, setShowPushToLiveAlert] = useState(false);

  /**
  * This method is used to handle edit widget title
  *
  * @param {*} widget
  * @param {*} name
  */
  const handleEditWidget = (widget, name) => {
    setSelectedWidget(widget);
    setWidgetTitle(name);
    setIsEdit(true);
  };

  /**
  * This method is used to handle save Title
  *
  * @param {*} widget
  * @param {*} sectionId
  * @param {*} configId
  */
  const handleSaveTitle = (widget, sectionId, configId) => {
    const requestData = {
      ...widget,
      sectionId,
      configId,
    };
    requestData.title = widgetTitle;
    updateSectionConfig(requestData, locale).then((response) => {
      if (response && response.success) {
        if (selectedPage && selectedPage.pageType === "CLP") {
          getPageConfigurationsByPageType(
            selectedPage.pageType, configId, locale,
          ).then((responseIn) => {
            if (responseIn && responseIn.success && responseIn.data) {
              setPageConfiguration(responseIn.data);
            }
          });
        } else {
          getPageConfigurations();
        }
        setIsEdit(false);
      }
    });
  };

  const toggleLandingPageStatus = () => {
    const { name: selectedPageType } = selectedPage;
    let catalogItemId = null;
    if (selectedPageType === "CLP") {
      catalogItemId = parentCategory;
    } else if (selectedPageType === "BLP") {
      catalogItemId = selectedBrand.id;
    } else {
      return;
    }
    const requestData = {
      pageType: selectedPageType,
      catalogItemId,
    };
    toggleCustomLandingPageFlag(requestData).then((response) => {
      if (response && response.success) {
        setCustomPageEnabled(!customPageEnabled);
      }
    });
  };

  /**
  * This method is used to toggle change status
  *
  * @param {*} widget
  * @param {*} sectionId
  * @param {*} configId
  * @param {*} active
  */
  const toggleActiveWidget = (widget, sectionId, configId, active) => {
    const requestData = {
      ...widget,
      sectionId,
      configId,
    };
    requestData.active = !active;

    updateSectionConfig(requestData, locale).then((response) => {
      if (response && response.success) {
        if (selectedPage && (selectedPage.pageType === "CLP" || selectedPage.pageType === "HOME")) {
          getPageConfigurationsByPageType(
            selectedPage.pageType, configId, locale,
          ).then((responseIn) => {
            if (responseIn && responseIn.success && responseIn.data) {
              if (requestData.active) {
                setNewlyActivatedWidgetType(sectionId);
              } else {
                setNewlyActivatedWidgetType(null);
              }
              setPageConfiguration(responseIn.data);
            }
          });
        } else {
          getPageConfigurations();
        }
        setIsEdit(false);
      }
    });
  };

  /**
  *This method is used to toogle dropdown
  *
  */
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleBrandDropDown = () => {
    setFilteredBrands(brands);
    toggleDropdownBrand(!dropdownBrand);
  }

  /**
     * This funciton is used query param
     * @param {Object} newPrams
     * @param {Boolean} update
     */
  const updateQueryPrams = (newPrams, update = true) => {
    const locationIn = history.location || location;
    if (locationIn) {
      const { search } = locationIn;
      let searchObject = qs.parse(search);
      searchObject = { ...searchObject, ...newPrams };
      history.push({
        pathname: location.pathname,
        search: `?${qs.stringify(searchObject)}`,
      });
    }
  };

  /**
  *This method is used to handle select page
  *
  * @param {String} PageTypes
  */
  const handleSelectPage = (page) => {
    setSelectedPage(page);
    setIsLoading(false);
    setParentCategory("");
    setCategoryLabel("");
    toggleDropdown();
    toggleDropdownBrand(false);
    setCustomPageEnabled(false);
    if (page) {
      updateQueryPrams({ pageType: page.pageType }, false);
    }
  };

  /**
    * This method is used to handle cancel
    *
    */
  const handleCancelTitle = () => {
    setWidgetTitle("");
    setIsEdit(false);
  };

  /**
    * This method is used to change parent category
    * @param {Array} categories
    */
  const handleParentCategory = (category) => {
    if (category) {
      setIsLoading(false);
      setParentCategory(category.id);
      setPageConfiguration(null);
    } else {
      setCategoryLabel("");
      setParentCategory("");
    }
  };

  /**
    * This method is used to change parent category
    * @param {Array} categories
    */
  const handleSelectBrand = (brand) => {
    const { id, customLandingPageConfigured } = brand;
    setParentCategory(id);
    setCustomPageEnabled(customLandingPageConfigured);
    setSelectedBrand(brand);
    toggleDropdownBrand(!dropdownBrand);
  };

  useEffect(() => {
    getSitePreviewConfigurations().then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        if (data) {
          setPreviewEnabled(data.previewEnabled || false);
          setPushToLiveEnabled(data.pushToLiveEnabled || false);
          const { previewSiteUrl: localPreviewSiteUrl } = data;
          setPreviewSiteUrl(localPreviewSiteUrl);
        }
      }
    });
  }, []);

  const handlePushToLive = async () => {
    setPushToLiveButtonEnabled(false);
    setShowPushToLiveAlert(true);
    replicateContent()
      .then((response) => {
        if (response && response.success) {
          const alertData = {
            type: "success",
            message:
               "Page contents pushed to live successfully, there may be a small delay for reflecting the same in live.",
          };
          setAlert(alertData);
        } else {
          const alertData = {
            type: "danger",
            message: "Something went wrong. Push to live failed",
          };
          setAlert(alertData);
        }
      })
      .catch((error) => {
        const alertData = {
          type: "danger",
          message: "Something went wrong. Push to live failed",
        };
        setAlert(alertData);
      });
  };

  const handleLanguageSelect = (localeLang) => {
    setLocale(localeLang);
    toggleDropdownLanguage(!dropdownLanguage);
    updateQueryPrams({ locale: localeLang });
  };

  const getReplicationStatus = async () => {
    getContentReplicationStatus()
      .then((response) => {
        if (
          response
           && response.success
           && ((response.data && response.data.completed) || !response.data)
        ) {
          setPushToLiveButtonEnabled(true);
          if (showPushToLiveAlert) {
            const alertData = {
              type: "success",
              message:
                 "The last content push to live is successfully completed.",
            };
            setAlert(alertData);
          }
          setShowPushToLiveAlert(true);
        } else {
          setPushToLiveButtonEnabled(false);
          if (showPushToLiveAlert) {
            const alertData = {
              type: "warning",
              message:
                 "Please wait some more time to reflect the changes in live.",
            };
            setAlert(alertData);
          }
          setShowPushToLiveAlert(true);
        }
      })
      .catch((error) => {
        setPushToLiveButtonEnabled(false);
        setShowPushToLiveAlert(true);
      });
    return null;
  };

  const handleModalOpen = () => {
    setIsOpen(true);
  };

  const handleModalClose = () => {
    setIsOpen(false);
    parseWidgetsObjecttoList();
  };
  const handleModalSubmit = () => {
    setIsOpen(false);
    reorderWidgetsList();
  };

  /**
    *This method is used to modify the list after drag
    *
    * @param {Array} list
    * @param {Number} startIndex
    * @param {Number} endIndex
    * @returns
    */

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  /**
    *This method is used ito on drag end
    *
    * @param {object} result
    * @returns
    */

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const rowData = reorder(
      screenWidgets,
      result.source.index,
      result.destination.index,
    );

    setScreenWidgets(rowData);
  };

  return (
    <PageCustomization
      getPageConfigurations={getPageConfigurations}
      pageConfiguration={pageConfiguration}
      isLoading={isLoading}
      isEdit={isEdit}
      selectedWidget={selectedWidget}
      setWidgetTitle={setWidgetTitle}
      widgetTitle={widgetTitle}
      handleEditWidget={handleEditWidget}
      handleSaveTitle={handleSaveTitle}
      handleCancelTitle={handleCancelTitle}
      toggleDropdown={toggleDropdown}
      dropdownOpen={dropdownOpen}
      handleSelectPage={handleSelectPage}
      selectedPageData={selectedPage}
      categoryId={parentCategory}
      handleModalOpen={handleModalOpen}
      isOpen={isOpen}
      toggleClose={handleModalClose}
      handleModalSubmit={handleModalSubmit}
      screenWidgets={screenWidgets}
      onDragEnd={onDragEnd}
      handleParentCategory={handleParentCategory}
      seoInformation={seoInformation}
      categoryLabel={categoryLabel}
      brands={brands}
      selectedBrand={selectedBrand}
      handleSelectBrand={handleSelectBrand}
      dropdownBrand={dropdownBrand}
      toggleDropdownBrand={toggleDropdownBrand}
      previewSiteUrl={previewSiteUrl}
      handlePushToLive={handlePushToLive}
      previewEnabled={previewEnabled}
      pages={pages}
      customPageEnabled={customPageEnabled}
      toggleLandingPageStatus={toggleLandingPageStatus}
      toggleActiveWidget={toggleActiveWidget}
      widgetConfigurations={widgetConfigurations}
      globalLayout={globalLayout}
      setCategoryLabel={setCategoryLabel}
      layouts={layouts}
      selectedLayout={selectedLayout}
      handleChangeLayout={handleChangeLayout}
      dropdownLayout={dropdownLayout}
      toggleDropdownLayout={toggleDropdownLayout}
      handleLanguageSelect={handleLanguageSelect}
      dropdownLanguage={dropdownLanguage}
      toggleDropdownLanguage={toggleDropdownLanguage}
      locale={locale}
      pushToLiveEnabled={pushToLiveEnabled}
      message={message}
      pushToLiveButtonEnabled={pushToLiveButtonEnabled}
      getReplicationStatus={getReplicationStatus}
      filteredBrands={filteredBrands}
      setFilteredBrands={setFilteredBrands}
      toggleBrandDropDown={toggleBrandDropDown}
    />
  );
};
export default PageCustomizationContainer;
