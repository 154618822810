/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

const constants = {
  LOCALE: {
    EN_QA: "en_QA",
    AR_QA: "ar_QA",
  },
  DATE_FORMAT: "DD/MM/YYYY",
  ORDER_MANAGMENT_ORDER_LISTING_SORT_BY_ORDER_DATE: "orderDate",
  NAVIGATION_MENU_POSITIONS: {
    WEB_HEADER: "WebHeader",
    WEB_FOOTER: "WebFooter",
    WEB_RESPONSIVE_HEADER: "WebResponsiveHeader",
    WEB_RESPONSIVE_FOOTER: "WebResponsiveFooter",
  },
  NAVIGATION_ITEM_TYPES: {
    category: "Category",
    product: "Product",
    brand: "Brand",
    custom: "Custom",
    system: "System",
  },
  CAMPAIGN_EXPERIENCE: {
    promotion: "promotion",
  },
  PROMOTION: {
    CLASS_ORDER: "Order",
    CONTEXT_ORDER: "Order",
    CLASS_SHIPPING: "Shipping",
    CONTEXT_SHIPPING: "Shipping",
    CLASS_PRODUCT: "Product",
    CONTEXT_PRODUCT: "Product",
    CONTEXT_PRODUCT_OPTION: "ProductOption",
    DISCOUNT_AMOUNT: "AmountPromotion",
    DISCOUNT_PRODUCTS: "QuantityPromotion",
    BUY_X_GET_Y_PROMOTION: "BuyXGetYPromotion",
    BUY_X_PAY_Y_PROMOTION: "BuyXPayYPromotion",
    PRODUCT_OPTION_PROMOTION: "ProductOptionPromotion",
    BONUS_OPTION_PROMOTION: "BonusProductPromotion",
    BUNDLE_PROMOTION: "BundlePromotion",
    DISCOUNT_BUY: "BuyOneGetOne",
    PERCENTAGE_OFF: "PercentageOff",
    FLAT_AMOUNT_OFF: "FlatAmountOff",
    FIXED_AMOUNT: "FixedAmount",
    BUY_X_PAY_Y: "BuyXPayY",
    BUY_X_GET_Y_FREE: "BuyXGetYFree",
    BUY_X_FIXED_AMOUNT: "BuyXFixedAmount",
    FREE_SHIPPING: "FreeShipping",
    FREE_ITEM: "FreeItem",
    SHEET_CONTEXT_PRODUCT: "Product",
    SHEET_CONTEXT_CATEGORY: "Category",
    SHEET_CONTEXT_BRAND: "Brand",
    SHEET_CONTEXT_BUNDLE: "Bundle",
    FILE_TYPES_PROMOTION:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel",
    BULK_UPLOAD_LIMIT: 500,
  },
  STATUS_SUCCESS: "SUCCESS",
  STATUS_INPROGRESS: "INPROGRESS",
  STATUS_WARNING: "WARNING",
  STATUS_FAILED: "FAILED",
  STATUS_INCOMPLETE: "Incomplete",
  STATUS_MERGED: "Merged",
  STATUS_ABANDONED: "Abandoned",
  ORGANIZATIONS: {
    ROOT: "Root",
    RETAILER: "Retailer",
    BRAND: "Brand",
    LOCATION: "Location",
    CHANNELGROUP: "ChannelGroup",
    CHANNEL: "Channel",
  },
  CAROUSAL_LIST: [
    "CAROUSAL_BANNER",
    "CAROUSAL_BANNER_TWO",
    "CAROUSAL_BANNER_THREE",
    "CAROUSAL_BANNER_FOUR",
    "CAROUSAL_BANNER_FIVE",
  ],
  SINGLE_BANNER_LIST: [
    "SINGLE_BANNER",
    "SINGLE_BANNER_TWO",
    "SINGLE_BANNER_THREE",
  ],
  PRODUCT_OPTIONS: {
    WARRANTY: "Warranty",
    INSTALLATION: "Installation",
    GIFTWRAP: "GiftWrap",
  },
  CUSTOMER_GROUP: {
    BIG_SPENDER: "BigSpender",
    EMPLOYEE: "Employee",
    EXACT_MATCH: "exactMatch",
    CONTAINS: "contains",
    FILE_TYPES_CUSTOMER_GROUP:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel",
  },
  CATEGORY: {
    FILE_TYPES_SEQUENCE:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel",
    BULK_UPLOAD_LIMIT: 3000,
    SHEET_CONTEXT_SEQEUENCE: "Sequence",
  },
  CART_MANAGEMENT: {
    FILE_TYPES_CART_LIMIT:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel",
    BULK_UPLOAD_LIMIT: 3000,
    SHEET_CONTEXT_SKU: "Sku",
  },
  PRODUCT_BULK_MAINTENANCE: {
    MAINTENANCE_TYPES: [{ name: "Colour Palette Upload", code: "Colourpaletteupload" }],
    SELECTED_MAINTENANCE_TYPES: { name: "Colour Palette Upload", code: "Colourpaletteupload" },
    COLOUR_PALETTE_UPLOAD: "Colourpaletteupload",
    DATA_UPLOAD_MODES: [{ name: "CREATE", code: "CREATE" }, { name: "UPDATE", code: "UPDATE" }, { name: "DELETE", code: "DELETE" }],
    DEFAULT_UPLOAD_MODE: { name: "CREATE", code: "CREATE" },
    CREATE: "CREATE",
    UPDATE: "UPDATE",
    DELETE: "DELETE",
  },
  COLOUR_PALETTE_MANAGEMENT: {
    FILE_TYPES_SEQUENCE:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel",
    BULK_UPLOAD_LIMIT: 8000,
    SHEET_CONTEXT_COLOUR_PALETTE: "ColourPalette",
    HEADER_KEYS: ["Colors Arabic", "Colors", "Colors Code", "Brand", "Product", "Size", "Base", "SAP ARTICLE", "Red", "Green", "Blue", "RGB HEX"],
    FIELD_VALIDATIONS: {
      Colors_Arabic: {
        name: "Colors Arabic",
        MAX_LENGTH: 200,
        REQUIRED: true,
      },
      Colors: {
        name: "Colors",
        MAX_LENGTH: 200,
        REQUIRED: true,
      },
      Colors_Code: {
        name: "Colors Code",
        MAX_LENGTH: 200,
        REQUIRED: true,
      },
      Brand: {
        name: "Brand",
        MAX_LENGTH: 200,
        REQUIRED: true,
      },
      Product: {
        name: "Product",
        MAX_LENGTH: 1000,
        REQUIRED: false,
      },
      Size: {
        name: "Size",
        MAX_LENGTH: 200,
        REQUIRED: false,
      },
      Base: {
        name: "Base",
        MAX_LENGTH: 200,
        REQUIRED: false,
      },
      SAP_ARTICLE: {
        name: "SAP ARTICLE",
        MAX_LENGTH: 200,
        REQUIRED: true,
      },
      Red: {
        name: "Red",
        MAX_LENGTH: 200,
        REQUIRED: true,
      },
      Green: {
        name: "Green",
        MAX_LENGTH: 200,
        REQUIRED: true,
      },
      Blue: {
        name: "Blue",
        MAX_LENGTH: 200,
        REQUIRED: true,
      },
      RGB_HEX: {
        name: "RGB HEX",
        MAX_LENGTH: 200,
        REQUIRED: true,
      },
    },
  },
  JOB_TYPES: {
    PRODUCT_SHADE_EXPORT_JOB: "productShadeExportJob",
    ATTRIBUTE_BULK_UPLOAD_JOB: "attributeBulkUploadJob",
  },

  COUPONS: {
    MIN_NUMBER: 5,
  },

  ESCAPABLE_WIDGETS: [
    "PAGE_HEADER",
    "PAGE_FOOTER",
    "USP_ICONS",
    "HEADER_TAPE",
    "BREADCRUMBS",
    "SEO_INFO",
  ],
};

export default constants;
