/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Roshna Accu
 */

import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

import { getTranslationConfig, getTranslatedInputsByPage } from "../../../api/translationServices";
import LocaleDropdown from "../components/LocaleDropdown";

const LocaleDropdownContainer = ({
  entityName, isIndocument, localeHandler,
}) => {
  const defaultLocale = useRef(null);
  const [locales, setLocales] = useState([]);
  const [dropdownLanguage, toggleDropdownLanguage] = useState(false);
  const [locale, setLocale] = useState();

  useState(() => {
    getTranslationConfig().then((response) => {
      if (response && response.success === true) {
        const { data } = response;
        setLocales(data.availableLocales);
        setLocale(data.defaultLocale);
        defaultLocale.current = data.defaultLocale;
      }
    });
  }, []);

  const handleLanguageSelect = async (localeLang) => {
    setLocale(localeLang);
    const isDefault = (localeLang === defaultLocale.current);
    let translatableProperties = [];
    if (!isDefault && isIndocument) {
      const response = await getTranslatedInputsByPage(entityName);
      if (response && response.success) {
        const { data } = response;
        translatableProperties = data;
      }
    }
    localeHandler(
      localeLang,
      isDefault,
      translatableProperties,
    );
    toggleDropdownLanguage(!dropdownLanguage);
  };
  return (
    <LocaleDropdown
      locales={locales}
      locale={locale}
      handleLanguageSelect={handleLanguageSelect}
      toggleDropdownLanguage={toggleDropdownLanguage}
      dropdownLanguage={dropdownLanguage}
    />
  );
};
LocaleDropdownContainer.propTypes = {
  entityName: PropTypes.string,
  isIndocument: PropTypes.bool,
  localeHandler: PropTypes.func.isRequired,
};
LocaleDropdownContainer.defaultProps = {
  entityName: "",
  isIndocument: false,
};

export default LocaleDropdownContainer;
