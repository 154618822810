/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * EditPageContainer
 *
 * @author Naseef O
 *
 */

import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import moment from "moment";
import EditPage from "../components/EditPage";
import commonUtils from "../../../common/utils/commonUtils";
import {
  createCustomPage,
  getCustomPageById,
  updateCustomPage,
} from "../../../api/pageConfigurationsServices";
import validateForm from "../../../common/utils/validateForm";

const EditPageContainer = () => {
  const location = useLocation();
  const history = useHistory();
  const { pageId } = commonUtils.getQueryParams(location);

  const [pageForm, setPageForm] = useState({
    pageHeader: "",
    url: "",
    pageTitle: "",
    metaDescription: "",
    metaKeywords: "",
    active: true,
    startDate: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
    endDate: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
  });
  const [content, setContent] = useState("");
  const [customPageData, setCustomPageData] = useState(null);

  const [isEdit, setIsEdit] = useState(false);

  const [language, setLanguage] = useState("en_QA");
  const localeHandler = (localeLang) => {
    setLanguage(localeLang);
  };

  useEffect(() => {
    if (language && pageId) {
      getCustomPageById(pageId, language).then(async (response) => {
        if (response && response.success && response.data) {
          const { data } = response;
          setCustomPageData(data);
          setPageForm({
            pageHeader: data.pageHeader,
            url: data.url,
            pageTitle: data.pageTitle,
            metaDescription: data.metaDescription,
            metaKeywords: data.metaKeywords,
            active: data.active,
            locale: data.locale,
            startDate: data.startDate,
            endDate: data.endDate,
          });
          setIsEdit(true);
          setContent(data.content);
        }
      });
    }
  }, [language]);

  const methods = validateForm();
  const {
    handleSubmit, register, errors, setError, clearError, setValue,
  } = methods;

  /**
     * This method is used to handle change page form
     *
     * @param {*} e
     */
  const handleChange = (e) => {
    const { value, name, checked } = e.target;
    clearError(["url"]);
    if (name === "startDate" || name === "endDate") {
      setPageForm({ ...pageForm, [name]: value.format("YYYY-MM-DD HH:mm:ss") });
    } else if (name === "active") {
      setPageForm({ ...pageForm, active: checked });
    } else {
      setPageForm({ ...pageForm, [name]: value });
    }
  };

  /**
     * This method is used to handle change editor
     *
     * @param {*} html
     */
  const handleChangeEditor = (e) => {
    setContent(e.target.value);
  };

  /**
     * This method is used to redirect to static pages list
     *
     */
  const handleRedirectToStaticPages = () => {
    history.push("/merchandising/static-pages");
  };

  const [codeContent, setCodeContent] = useState("");

  /**
     * This method is used to handle update and save custom page
     *
     * @param {*} value
     */
  const handleSave = (value) => {
    const requestData = {
      ...customPageData,
      ...pageForm,
      content,
      locale: language,
    };
    if (value) {
      if (isEdit) {
        updateCustomPage(requestData, language).then((response) => {
          if (response && response.success) {
            handleRedirectToStaticPages();
          } else {
            setError("url", "noMatch", "URL already exists");
          }
        });
      } else {
        createCustomPage(requestData, language).then((response) => {
          if (response && response.success) {
            handleRedirectToStaticPages();
          } else {
            setError("url", "noMatch", "URL already exists");
          }
        });
      }
    }
  };

  /**
     * This method is used to handle change code editor
     * @param {Event} e
     */
  const handleChangeCodeEditor = (e) => {
    setCodeContent(e.target.value);
  };

  return (
    <EditPage
      isEdit={isEdit}
      handleChange={handleChange}
      pageForm={pageForm}
      handleSave={handleSave}
      handleChangeEditor={handleChangeEditor}
      content={content}
      handleSubmit={handleSubmit}
      register={register}
      errors={errors}
      handleRedirectToStaticPages={handleRedirectToStaticPages}
      localeHandler={localeHandler}
      language={language}
      setContent={setContent}
      codeContent={codeContent}
      setCodeContent={setCodeContent}
      handleChangeCodeEditor={handleChangeCodeEditor}
      setValue={setValue}
    />
  );
};

export default EditPageContainer;
