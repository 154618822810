/**
 * Copyright(c) 2022 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Abdul Razack
 */

import React, { useEffect, useState } from "react";
import XLSX from "xlsx";
import colourPaletteUtils from "../../../common/utils/colourPaletteUtils";
import constants from "../../../common/utils/constants";
/** ===== COMPONENTS  =========== */
import ColourPaletteManagement from "../components/ColourPaletteManagement";
import ColourPaletteStatusModal from "../components/ColourPaletteUploadStatusModal";
/** ===== API SERVICE FUNCTIONS =========== */
import {
  updateBatchConfig, getJobHistory, getBatchStatus,
} from "../../../api/InventoryReportServices";
import { getJobStepAuditByJobId, getJobStepExAuditByJobId, downloadProcessedFile } from "../../../api/jobConfigurationService";
const {
  PRODUCT_BULK_MAINTENANCE: {
    DATA_UPLOAD_MODES,
    DEFAULT_UPLOAD_MODE,
  },
  JOB_TYPES: {
    PRODUCT_SHADE_EXPORT_JOB,
    ATTRIBUTE_BULK_UPLOAD_JOB,
  },
} = constants;

const ColourPaletteManagementContainer = () => {
  const [selectedUploadMode, setSelectedUploadMode] = useState(DEFAULT_UPLOAD_MODE);
  const [modalAttibutes, setModalAttributes] = useState({
    isOpen: false,
    content: "",
    header: "",
    errors: [],
  });

  const [isExporting, setIsExporting] = useState(false);
  const [jobHistoryData, setJobHistoryData] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorJobId, setErrorJobId] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [isProcessingUploadJob, setisProcessingUploadJob] = useState(false);
  const [message, setMessage] = useState({
    type: null,
    message: "",
  });

  const setAlert = (alertData) => {
    setMessage(alertData);
    setTimeout(() => {
      setMessage({ type: null, message: "" });
    }, 6000);
  };

  const handleUploadMode = (mode) => {
    const selectedMode = DATA_UPLOAD_MODES.find((uploadMode) => uploadMode.name === mode);
    if (selectedMode) {
      setSelectedUploadMode(selectedMode);
    }
  };

  const downloadFile = async (id, fileName, type) => {
    const response = await downloadProcessedFile(id, type);
    if (response) {
      const { request: responseData } = response || {};
      if (responseData) {
        const disposition = responseData.getResponseHeader('content-disposition');
        const matches = /"([^"]*)"/.exec(disposition);
        const filename = (matches != null && matches[1] ? matches[1] : fileName);
        const blob = new Blob([responseData.response], { type: 'application/xml' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  const documentDowloadSteps = (jobId, type) => {
    getJobStepAuditByJobId(jobId).then(async (response) => {
      if (response && response.success && response.data && response.data.length > 0) {
        const stepId = response.data[0].id;
        const size = 1;
        const page = 0;
        getJobStepExAuditByJobId(jobId, stepId, page, size).then(async (stepAuditResponse) => {
          if (stepAuditResponse && stepAuditResponse.success && stepAuditResponse.data && stepAuditResponse.data.content.length > 0) {
            downloadFile(stepAuditResponse.data.content[0].id, stepAuditResponse.data.content[0].fileName || stepAuditResponse.data.content[0].filePath, type);
          } else {
            const alertData = {
              type: "warning",
              message: "This file does not contain any products, please export once again and check",
            };
            setAlert(alertData);
          }
        });
      }
    });
  };

  const jobHistoryServiceCall = (reqObj) => {
    getJobHistory(reqObj).then(async (response) => {
      if (response && response.data) {
        const { data } = response;
        if (data && data.content) {
          if (reqObj.searchTerm === PRODUCT_SHADE_EXPORT_JOB) {
            setJobHistoryData(data.content);
            setIsExporting(false);
          } else if (reqObj.searchTerm === ATTRIBUTE_BULK_UPLOAD_JOB) {
            if (data.content[0]?.status === "FAILED") {
              setHasError(true);
              setErrorJobId(data.content[0]?.id);
            } else {
              setHasError(false);
              setErrorJobId("");
            }
          }
        }
      }
    });
  };
  let uploadOrDownloadTimeout = null;
  const callRecursively = (type, method) => {
    uploadOrDownloadTimeout = setTimeout(() => {
      method({ type });
    }, 12000);
  };

  const checkUploadOrDownloadStatus = async ({ type }) => {
    const response = await getBatchStatus(type);
    if (response && response.success) {
      if (response.data === true) {
        if (type === PRODUCT_SHADE_EXPORT_JOB) {
          setIsExporting(true);
        } else if (type === ATTRIBUTE_BULK_UPLOAD_JOB) {
          setisProcessingUploadJob(true);
        }
        callRecursively(type, checkUploadOrDownloadStatus);
      } else {
        setisProcessingUploadJob(false);
        jobHistoryServiceCall({
          page: 0,
          size: 1,
          status: null,
          jobConfigId: null,
          productId: "",
          jobConfigType: null,
          searchTerm: type,
          startDate: null,
          endDate: null,
        });
      }
    }
  };

  useEffect(() => {
    checkUploadOrDownloadStatus({ type: ATTRIBUTE_BULK_UPLOAD_JOB });
    checkUploadOrDownloadStatus({ type: PRODUCT_SHADE_EXPORT_JOB });
    return () => clearTimeout(uploadOrDownloadTimeout);
  }, []);


  const activateBatchScheduler = async (batchSchedulerDetails) => {
    const response = await updateBatchConfig(batchSchedulerDetails);
    setIsUploading(false);
    if (response && response.data === true && response.success) {
      setisProcessingUploadJob(true);
      checkUploadOrDownloadStatus({ type: ATTRIBUTE_BULK_UPLOAD_JOB });
    }
  };

  const getSheetHeaders = (sheet) => {
    var headers = [];
    var range = XLSX.utils.decode_range(sheet['!ref']);
    var C, R = range.s.r; /* start in the first row */
    for (C = range.s.c; C <= range.e.c; ++C) {
      var cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })] /* find the cell in the first row */
      var hdr = "UNKNOWN " + C;
      if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);
      headers.push(hdr);
    }
    return headers;
  }

  const handleColourPaletteBulkImport = async (e) => {
    if (e && e.target && e.target.files) {
      setModalAttributes({
        isOpen: false,
        content: "",
        header: "",
        errors: [],
      });
      const file = e.target.files[0];
      const fileName = file.name;

      if (!fileName.endsWith(".xlsx") && !fileName.endsWith(".xls")) {
        setModalAttributes({
          isOpen: true,
          content: "Invalid file type. Please upload a XLSX/XLS file",
          errors: [],
          header: "Error",
        });
      } else {
        const { name } = e.target;
        const reader = new FileReader();
        const readerBase64 = new FileReader();
        readerBase64.onloadend = () => {
          const base64String = readerBase64.result
            .replace("data:", "")
            .replace(/^.+,/, "");

          setIsUploading(true);
          activateBatchScheduler({
            criteria: {
              data: base64String,
              mode: selectedUploadMode.code,
            },
            type: ATTRIBUTE_BULK_UPLOAD_JOB,
          });
        };

        reader.onload = async (f) => {
          try {
            const data = new Uint8Array(f.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const sheetNameList = workbook.SheetNames;
            const rawData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetNameList[0]], { raw: true });
            const parsedData = await colourPaletteUtils.parseDataFromSheet(rawData, getSheetHeaders(workbook.Sheets[sheetNameList[0]]));
            if (parsedData.isValid) {
              readerBase64.readAsDataURL(file);
            } else {
              setModalAttributes({
                isOpen: true,
                content: parsedData.message || "Something went wrong.Please try again",
                errors: parsedData.errors || [],
                header: "Error",
              });
            }
          } catch (error) {
            setModalAttributes({
              isOpen: true,
              content: "Something went wrong.Please try again",
              errors: [],
              header: "Error",
            });
          }
        };
        reader.readAsArrayBuffer(file);
      }
      e.target.value = null;
    }
  };

  const downloadBulkImportTemplate = (name, context) => {
    const fileName = `${name}_template.xlsx`;
    const templateData = colourPaletteUtils.getImportSheetTemplate(context);
    const ws = XLSX.utils.json_to_sheet(templateData);
    const wb = XLSX.utils.book_new();
    ws["!cols"] = [
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
    ];
    XLSX.utils.book_append_sheet(wb, ws, name);
    XLSX.writeFile(wb, fileName);
  };
  const exportColourPalette = async (event) => {
    const response = await updateBatchConfig({
      criteria: {},
      type: PRODUCT_SHADE_EXPORT_JOB,
    });
    if (response && response.data === true && response.success) {
      setIsExporting(response.data);
      checkUploadOrDownloadStatus({ type: PRODUCT_SHADE_EXPORT_JOB });
    }
  };

  return (
    <>
      <ColourPaletteManagement
        DataUploadModes={DATA_UPLOAD_MODES}
        selectedUploadMode={selectedUploadMode}
        isExporting={isExporting}
        hasError={hasError}
        errorJobId={errorJobId}
        isUploading={isUploading}
        isProcessingUploadJob={isProcessingUploadJob}
        jobHistoryData={jobHistoryData}
        handleUploadMode={handleUploadMode}
        handleColourPaletteBulkImport={handleColourPaletteBulkImport}
        downloadBulkImportTemplate={downloadBulkImportTemplate}
        exportColourPalette={exportColourPalette}
        documentDowloadSteps={documentDowloadSteps}
        message={message}
      />
      <ColourPaletteStatusModal
        isOpen={modalAttibutes.isOpen}
        content={modalAttibutes.content}
        header={modalAttibutes.header}
        errors={modalAttibutes.errors}
        togglClose={() => setModalAttributes({ ...modalAttibutes, isOpen: false })}
      />
    </>
  );
};
export default ColourPaletteManagementContainer;
