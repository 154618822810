// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Copyright(c) 2020 Mozanta Technologies Private Ltd.\n * \n * All rights reserved.                \n * \n * This software is the confidential and proprietary information of Mozanta (\"Confidential\n * Information\"). You shall not disclose such Confidential Information and shall use it only in\n * accordance with the terms of the contract agreement you entered into with Mozanta.\n * \n * @author Angela\n */\n\n.CacheManagement_collapseIcon__3OQaw>img {\n  width: 15px;\n  height: 15px;\n  transition: 0.6s ease-out;\n  float: right;\n}\n\n.CacheManagement_openCollapse__2grHG {\n  transform: rotateZ(-90deg);\n}\n\n.CacheManagement_closeCollapse__3gSNP {\n  transform: rotateZ(90deg);\n}\n\n.CacheManagement_block2__1YJT3 {\n  padding-top: 20px;\n  padding-bottom: 20px;\n}\n\n.CacheManagement_borderRadius2__2VUQ8 {\n  border-radius: 0.125rem !important;\n}\n\n.CacheManagement_secondaryColor__3IqgC {\n  background-color: #757575 !important;\n  border-color: #757575 !important;\n}\n\n.CacheManagement_primaryColor__21Tcl {\n  background: #17a2b8 !important;\n  border-color: #17a2b8 !important;\n}\n\n\n.CacheManagement_btn__2diFB {\n  min-width: 7rem;\n}\n\n.CacheManagement_outOfStockSwitch__1ANBh{\n    margin-left: 15px;\n}\n\n.CacheManagement_zeroPriceProductStatusSwitch__vNpB-{\n    margin-left: 15px;\n}", ""]);
// Exports
exports.locals = {
	"collapseIcon": "CacheManagement_collapseIcon__3OQaw",
	"openCollapse": "CacheManagement_openCollapse__2grHG",
	"closeCollapse": "CacheManagement_closeCollapse__3gSNP",
	"block2": "CacheManagement_block2__1YJT3",
	"borderRadius2": "CacheManagement_borderRadius2__2VUQ8",
	"secondaryColor": "CacheManagement_secondaryColor__3IqgC",
	"primaryColor": "CacheManagement_primaryColor__21Tcl",
	"btn": "CacheManagement_btn__2diFB",
	"outOfStockSwitch": "CacheManagement_outOfStockSwitch__1ANBh",
	"zeroPriceProductStatusSwitch": "CacheManagement_zeroPriceProductStatusSwitch__vNpB-"
};
module.exports = exports;
