/* eslint-disable import/prefer-default-export */
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Angela
 */


import httpApi from "./httpApi";

/**
 * This method is used to delete all cache
 */
const deleteAllCache = async () => {
  const response = await httpApi.delete("/v1/cache/all");
  if (response && response.data) {
    return response.data;
  }
  return null;
};

export {
  deleteAllCache,
};
