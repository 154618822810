/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */
import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ========= TAG COMPONENTS ========= */
import FormGroup from "../../../common/core/FormGroup";
import Col from "../../../common/core/Col";
import Row from "../../../common/core/Row";
import Input from "../../../common/core/Input";
import Label from "../../../common/core/Label";

/** ========= SUB COMPONENT ========= */

/** ========= CUSTOM COMPONENTS ========= */
import Select from "../../../common/components/MultiSelect/Select";
import FormFeedback from "../../../common/core/FormFeedback";

/** ========= MODULE STYLES ========= */
import styles from "../css/MenuForm.module.css";

const MenuForm = (props) => {
  const {
    name, position, active,
    positions,
    handleFormChange, handleChange, validator, locale, isEdit,
  } = props;

  /** internationalization using i18n from common and site-navigation  */
  const { t } = useTranslation(["common", "site-navigation"]);
  const locales = [
    { value: "", label: t("site-navigation:placeholder_locale"), disabled: true },
    { label: "English", value: "en_QA" },
    { label: "Arabic", value: "ar_QA" },
  ];
  /** validation methods */
  const { register, errors } = validator;

  return (
    <>
      <Row>
        <Col xs="12">
          <FormGroup>
            <Label className="formTextLabel">{t("site-navigation:label_name")}</Label>
            <span className="pl-1 text-danger">*</span>
            <Input
              className="formText"
              placeholder={t("site-navigation:placeholder_name")}
              name="name"
              value={name}
              onChange={handleFormChange}
              innerRef={
                register({
                  required: "Please enter a menu name",
                  minLength: {
                    value: 3,
                    message: "Please enter a menu name",
                  },
                })
              }
              invalid={!!(errors.name && errors.name.message)}
            />
            <FormFeedback>{errors.name && errors.name.message}</FormFeedback>
          </FormGroup>
        </Col>

        <Col xs="12" sm="7" lg="5">
          <FormGroup>
            <Label className="formTextLabel">{t("site-navigation:label_locale")}</Label>
            <Select
              className="formText"
              placeholder={t("site-navigation:placeholder_locale")}
              name="locale"
              id="id-of-position"
              disabled={isEdit}
              onChange={handleFormChange}
              value={locale}
              options={locales}
              innerRef={
                register({
                  required: "Please select locale",
                })
              }
              invalid={!!(errors.locale && errors.locale.message)}
            />
            <FormFeedback>{errors.locale && errors.locale.message}</FormFeedback>
          </FormGroup>
        </Col>
        <Col xs="12" sm="7" lg="5">
          <FormGroup>
            <Label className="formTextLabel">{t("site-navigation:label_position")}</Label>
            <Select
              className="formText"
              placeholder={t("site-navigation:placeholder_position")}
              name="position"
              id="id-of-position"
              onChange={handleFormChange}
              value={position}
              innerRef={
                register({
                  required: "Please select position",
                })
              }
              disabled={isEdit}
              invalid={!!(errors.position && errors.position.message)}
              options={[
                { value: "", label: t("site-navigation:placeholder_position"), disabled: true },
                ...(Array.isArray(positions)
                  && positions.map((value) => ({ value, label: value }))),
              ]}
            />
            <FormFeedback>{errors.position && errors.position.message}</FormFeedback>
          </FormGroup>
        </Col>

        <Col xs="6" sm="5" lg="4">
          <FormGroup className={clsx(styles.noLabelFormGroup, "d-flex")}>
            <Label className="formTextLabel">{t("common:label_status")}</Label>
            <div
              className={clsx(
                "custom-control",
                "custom-switch",
                "ml-3",
              )}
            >
              <input
                type="checkbox"
                className="custom-control-input"
                id="enable-menu"
                name="active"
                checked={active}
                onChange={() => handleChange("active", !active)}
              />
              <Label className="custom-control-label" htmlFor="enable-menu" />
            </div>
          </FormGroup>
        </Col>

      </Row>
    </>
  );
};

MenuForm.propTypes = {
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  /** form data */
  positions: PropTypes.arrayOf(PropTypes.any).isRequired,
  /** form methods */
  handleFormChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  validator: PropTypes.objectOf(PropTypes.any).isRequired,
  locale: PropTypes.string.isRequired,
  isEdit: PropTypes.bool.isRequired,
};

export default MenuForm;
