/**
 * Copyright(c) 2022 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Abdul Razack
 */

import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ===== TAG COMPONENTS =========== */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Button from "../../../common/core/Button";
import FormGroup from "../../../common/core/FormGroup";
import Alert from "../../../common/core/Alert";
import Select from "../../../common/components/Select";
import CustomizationTable from "../../../common/components/Table";

/** ===== MODULE STYLES =========== */
import styles from "../css/ColourPaletteManagement.module.css";
import downloadIconfrom from "../../../common/assets/images/svg/file-upload.svg";
import publishIcon from "../../../common/assets/images/svg/publish.svg";
import Spinner from "../../../common/core/Spinner";
import constants from "../../../common/utils/constants";

const ColourPaletteManagement = ({
  DataUploadModes,
  selectedUploadMode,
  isExporting,
  hasError,
  errorJobId,
  isUploading,
  isProcessingUploadJob,
  jobHistoryData,
  handleUploadMode,
  handleColourPaletteBulkImport,
  downloadBulkImportTemplate,
  exportColourPalette,
  documentDowloadSteps,
  message,
}) => {
  const {
    COLOUR_PALETTE_MANAGEMENT, JOB_TYPES: {
      PRODUCT_SHADE_EXPORT_JOB,
      ATTRIBUTE_BULK_UPLOAD_JOB,
    },
  } = constants;
  const { t } = useTranslation(["common", "globalSettings", "product-bulk-maintenance"]);
  return (
    <>
      <Row className={clsx(styles.block2, "common-page-card")}>
        <Col className="mb-2" xs="12" sm="12" md="12">
          <span
            className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}
          >
            {t("product-bulk-maintenance:sub_title_colour_palette_upload")}
          </span>
        </Col>
        <Col className="mb-4" xs="12" sm="12" md="12">
          <Row style={{ alignItems: "baseline" }}>
            <Col className="mb-2" xs="12" sm="12" md="3">
              <span
                className={clsx(styles.pageSelectionText, "formTextLabel")}
              >
                {t("product-bulk-maintenance:label_data_upload_mode")}
              </span>
            </Col>
            <Col className="mb-2" xs="12" sm="12" md="3">
              <FormGroup>
                <Select
                  className="formText mt-2"
                  placeholder="Select upload mode"
                  id="uploadmode"
                  value={selectedUploadMode.name}
                  onChange={(e) => {
                    handleUploadMode(e.target.value);
                  }}
                  options={[
                    ...DataUploadModes.map((DataUploadMode) => ({ value: DataUploadMode.code, label: DataUploadMode.name })),
                  ]}
                />
              </FormGroup>
            </Col>
            <Col className="mb-2" xs="12" sm="12" md="6">
              <div>
                <FormGroup>
                  <span
                    className=" mr-3 btn btn-light border text-primary"
                    role="presentation"
                    title="Download Template"
                    onClick={() => downloadBulkImportTemplate("colourPalettes", COLOUR_PALETTE_MANAGEMENT.SHEET_CONTEXT_COLOUR_PALETTE)}
                  >
                    <img
                      src={downloadIconfrom}
                      alt="download"
                    />
                  </span>
                  <span
                    className="mr-3 "
                    title="Upload Colour Palette File"
                  >
                    <label htmlFor="colourPaletteFile" className="btn btn-primary mb-0 ">
                      {
                        isUploading
                          ? <Spinner size="sm" />
                          : (
                            <img
                              src={publishIcon}
                              alt="upload"
                            />
                          )
                      }
                      <input
                        name="colourPalette"
                        id="colourPaletteFile"
                        accept={COLOUR_PALETTE_MANAGEMENT.BULK_UPLOAD_LIMIT}
                        type="file"
                        className="d-none"
                        onChange={
                          (e) => handleColourPaletteBulkImport(e)
                        }
                      />
                    </label>
                  </span>
                  {hasError && !isProcessingUploadJob && (
                    <Button
                      onClick={() => documentDowloadSteps(errorJobId, ATTRIBUTE_BULK_UPLOAD_JOB)}
                      className={clsx(styles.borderRadius2, "text-uppercase", "text-truncate", "btn-tertiary", "button-font-size")}
                    >
                      {t("product-bulk-maintenance:button_view_error")}
                    </Button>
                  )}
                  {
                    isProcessingUploadJob
                    && (
                      <span className="formTextLabel" align="center" valign="center">
                        <Spinner size="sm" />
                        <img src={publishIcon} alt="loading" />
                        {t("product-bulk-maintenance:alert_batch_processing_started")}
                      </span>
                    )
                  }
                </FormGroup>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="mb-2" xs="12" sm="12" md="3">
              <span
                className={clsx(styles.pageSelectionText, "formTextLabel")}
              >
                {t("product-bulk-maintenance:label_download_colour_palette")}
              </span>
            </Col>
            <Col className="mb-2" xs="12" sm="12" md="3">
              <FormGroup>
                <Button
                  onClick={() => exportColourPalette()}
                  className={clsx(styles.borderRadius2, "text-uppercase", "text-truncate", "btn-tertiary", "button-font-size")}
                >
                  {t("product-bulk-maintenance:button_export")}
                </Button>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="mb-2" xs="12" sm="12" md="12">
              {isExporting
                ? (
                  <div className="formTextLabel" align="center" valign="center">
                    <Spinner size="sm" />
                    <img src={publishIcon} alt="loading" />
                    {t("product-bulk-maintenance:alert_download_colour_palette_started")}
                  </div>
                )
                : (
                  <div className="custom-table">
                    <CustomizationTable
                      customRows={[
                        {
                          label: "Status",
                          value: "status",
                          path: "status",
                          transform: "",
                        },
                        {
                          label: "Start Date",
                          value: "startDate",
                          path: "startDate",
                          transform: "",
                          type: "date",
                        },
                        {
                          label: "End Date",
                          value: "endDate",
                          path: "endDate",
                          transform: "",
                          type: "date",
                        },
                        {
                          label: "Actions",
                          value: "",
                          path: "render",
                          render: (row) => (
                            <button type="button" className="btn btn-sm btn-info text-white" onClick={() => { documentDowloadSteps(row.id, PRODUCT_SHADE_EXPORT_JOB); }}>
                              Download
                            </button>
                          ),
                          transform: "",
                        }]}
                      customizationData={jobHistoryData}
                      isStatusEnable={false}
                      isDeleteEnable={false}
                      isEditEnable={false}
                    />
                    {
                      message && message.type && (
                        <Alert color={message.type}>
                          {message.message}
                        </Alert>
                      )
                    }
                  </div>
                )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
ColourPaletteManagement.defaultProps = {
  jobHistoryData: [],
  isUploading: false,
  isProcessingUploadJob: false,
  errorJobId: "",
  documentDowloadSteps: () => { },
};
ColourPaletteManagement.propTypes = {
  DataUploadModes: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedUploadMode: PropTypes.objectOf(PropTypes.any).isRequired,
  isExporting: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  errorJobId: PropTypes.string,
  isUploading: PropTypes.bool,
  isProcessingUploadJob: PropTypes.bool,
  jobHistoryData: PropTypes.arrayOf(PropTypes.any),
  handleUploadMode: PropTypes.func.isRequired,
  handleColourPaletteBulkImport: PropTypes.func.isRequired,
  downloadBulkImportTemplate: PropTypes.func.isRequired,
  exportColourPalette: PropTypes.func.isRequired,
  documentDowloadSteps: PropTypes.func,
  message: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ColourPaletteManagement;
