/**
 * Copyright(c) 2022 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Abdul Razack
 */
import React from "react";
import PropTypes from "prop-types";
import Modal from "../../../common/core/Modal";
import ModalBody from "../../../common/core/ModalBody";
import ModalHeader from "../../../common/core/ModalHeader";
import ModalFooter from "../../../common/core/ModalFooter";
import Col from "../../../common/core/Col";
import Row from "../../../common/core/Row";
import CustomizationTable from "../../../common/components/Table";

const ColourPaletteStatusModal = (props) => {
  const {
    isOpen,
    togglClose,
    content,
    header,
    errors,
  } = props;
  return (
    <>
      <Modal size="lg" isOpen={isOpen}>
        <ModalHeader>
          {header}
        </ModalHeader>
        <ModalBody>
          {content}
          {Array.isArray(errors) && errors.length > 0
            && (
              <Row>
                <Col xs="6" sm="12" className="mt-5">
                  <div className="custom-table">
                    <CustomizationTable
                      customRows={[
                        {
                          label: "Description",
                          path: "message",
                        },
                        {
                          label: "Row",
                          path: "row",
                        },
                      ]}
                      customizationData={errors}
                      isStatusEnable={false}
                      isDeleteEnable={false}
                      isEditEnable={false}
                      isSerialNoEnabled
                    />
                  </div>
                </Col>
              </Row>
            )}
        </ModalBody>
        <ModalFooter>
          <button type="button" className="secondaryButton" onClick={togglClose}>Ok</button>
        </ModalFooter>
      </Modal>
    </>
  );
};
ColourPaletteStatusModal.defaultProps = {
  errors: [],
};
ColourPaletteStatusModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  togglClose: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.any),
};
export default ColourPaletteStatusModal;
