/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Angela
 */


import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Col from "../../../common/core/Col";
import Row from "../../../common/core/Row";
import Label from "../../../common/core/Label";
import FormGroup from "../../../common/core/FormGroup";
import Button from "../../../common/core/Button";
import CardBody from "../../../common/core/CardBody";
import styles from "../css/CacheManagement.module.css"
import Alert from "../../../common/core/Alert";

const CacheManagement = ({ toggleModal, message }) => {

  return (
    <>
      {
        message && message.type && (
          <Alert color={message.type}>
            {message.message}
          </Alert>
        )
      }
      <Row className="pageHeader common-page-card">
        <Col xs="12" md="7" className="text-truncate">
          <span className="pageText">Cache</span>
        </Col>
      </Row>
      <Row className={clsx(styles.block2, "common-page-card")}>
        <Col xs="12">
          <CardBody>
            <Col xs="12" sm="6">
              <FormGroup className="d-flex">
                <Label className="formTextLabel mr-4 mt-2">
                  Remove all cache
</Label>
                <Button type="submit" onClick={toggleModal} color="primary" className={clsx(styles.borderRadius2, styles.btn, styles.primaryColor, "text-truncate", "mr-1", "button-font-size")}>
                  CLEAR
          </Button>
              </FormGroup>
            </Col>
          </CardBody>
        </Col>
      </Row>
    </>
  );
};
CacheManagement.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  message: PropTypes.objectOf(PropTypes.any).isRequired
};

export default CacheManagement;
